import { createMarketplaceHelpers } from '@sportscardinvestor/marketplace-helpers';
import { type Marketplace, isMarketplace } from '@sportscardinvestor/sci-ui/utils/types';
import { getRequiredConfigVar } from '../publicConfig';

export const marketplaceHelpers = createMarketplaceHelpers({
  ebay: {
    directAuctionPriceCampaignId: getRequiredConfigVar('NEXT_PUBLIC_EBAY_DIRECT_AUCTION_CAMPAIGN_ID'),
    directFixedPriceCampaignId: getRequiredConfigVar('NEXT_PUBLIC_EBAY_DIRECT_FIXED_PRICE_CAMPAIGN_ID'),
    searchCampaignId: getRequiredConfigVar('NEXT_PUBLIC_EBAY_SEARCH_CAMPAIGN_ID'),
    rotationId: getRequiredConfigVar('NEXT_PUBLIC_EBAY_ROTATION_ID'),
  },
  pwcc: {
    partnerId: getRequiredConfigVar('NEXT_PUBLIC_PWCC_PARTNER_ID'),
    adProgramId: getRequiredConfigVar('NEXT_PUBLIC_PWCC_AD_PROGRAM_ID'),
    impactTrackingId: getRequiredConfigVar('NEXT_PUBLIC_PWCC_IMPACT_TRACKING_ID'),
  },
});

const defaultMarketplace = 'ebay_jobs' as const;

export function ensureValidMarketplace(source: string | null): Marketplace {
  return source && isMarketplace(source) ? source : defaultMarketplace;
}
