import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { ChartUntrackedCollectiblesDialogProps } from './types';

const { invokeDialog: chartCustomCollectible, registerDialog } = createDialogSingleton<
  ChartUntrackedCollectiblesDialogProps,
  boolean
>();

export { chartCustomCollectible, registerDialog };
export type ChartUntrackedCollectiblesDialogProviderProps = DialogProviderProps<
  ChartUntrackedCollectiblesDialogProps,
  boolean
>;
