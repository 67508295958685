import { SalePlatform } from '@sportscardinvestor/schemas';
import { CollectibleType } from './collectibleType';

export interface ForSaleListingItemBase {
  collectibleId: number;
  collectibleType: CollectibleType;
  listingId: number;
  title: string;
  imageUrl: string;
  listingType: string; // ex. "Store"
  sellingPrice: null | number;
  totalItemListingsCount: number;
  sellerInfo: SellerInfo;
  endDateTime: null | string;
  // auction
  auctionId: string;
  auctionUrl: string;
  auctionBidsCount: null | number;
  // best offer
  bestOfferEnabled: boolean;
  offerPrice: null | number;
  offerPriceMessage: null | string;
  // buy it now
  buyItNowAvailable: boolean;
  buyItNowCurrency: string;
  buyItNowPrice: number | null;
  priceChangeAmount: number;
  priceChangePercentage: number;
  lastSale: string | null;
}

export interface ForSaleListingItemSportsCard extends ForSaleListingItemBase {
  playerName: null | string;
  setName: null | string;
  setYear: null | number;
}

export interface ForSaleListingItemSealedWax extends ForSaleListingItemBase {
  setName: null | string;
  setYear: null | number;
}

export type ForSaleListingItemUnknownType = ForSaleListingItemBase;

export type ForSaleListingItem =
  | ForSaleListingItemSportsCard
  | ForSaleListingItemSealedWax
  | ForSaleListingItemUnknownType;

export interface CompletedSalesListingItemBase {
  listingId: number;
  title: string;
  imageUrl: string;
  listingType: string; // ex. "Store"
  sellingPrice: null | number;
  listingStatus: string;
  endDateTime: string | null;
  externalSaleId: string | null;
  startDateTime: string;
  location: string;
  soldPrice: number;

  // collectible
  collectibleId: number;
  collectibleType: CollectibleType;
  cardSetId: null | number;

  // auction
  auctionId: string;
  auctionPaymentStatus: null | string;
  auctionUrl: string | null;
  auctionBidsCount: null | number;

  // best offer
  bestOfferEnabled: boolean;
  offerPrice: null | number;
  offerPriceMessage: null | string;
  offerPriceChecked: boolean | null;
  offerPriceCheckedCount: null | number;

  // buy it now
  buyItNowAvailable: boolean;

  // seller
  sellerInfo: SellerInfo;
  sellerEbayGoodStanding: null | boolean;
  sellerFeedbackRating: number | null;

  // buyer
  buyerAccountCreatedAt: string | null;
  buyerFeedbackRating: number | null;

  // system metadata
  autoExcluded: boolean;
  autoExcludedAverage: number | null;
  autoExcludedRange: null;
  autoExcludedTier: null;
  autoExcludedVariance: null;
  createdAt: string | null;
  updatedAt: string | null;
  isDeleted: boolean;
  isFlaggedByUser: boolean;
  isIncluded: boolean;
  isFromSeed: boolean;
  jobMasterCollectibleId: null | number;
  lastAuctionPaymentCheck: null;
}

export interface CompletedSalesListingItemSportsCard extends CompletedSalesListingItemBase {
  // system metadata
  autoExcludedImageSimilarity: null;
  jobMasterCardSetId: null | number;
}
export type CompletedSalesListingItemSealedWax = CompletedSalesListingItemBase;
export type CompletedSalesListingItemUnknownType = CompletedSalesListingItemBase;

export type CompletedSalesListingItem =
  | CompletedSalesListingItemSportsCard
  | CompletedSalesListingItemSealedWax
  | CompletedSalesListingItemUnknownType;

export const marketplaces = [
  'ebay_auctions',
  'myslabs',
  'pwcc',
  'goldin_auctions',
  'heritage',
  'pristine_auction',
  '90s_auctions',
  'huggins_and_scott',
  'lelands',
  'love_of_the_game',
  'cardshq',
] as const;
export type Marketplace = (typeof marketplaces)[number];

export interface SellerInfo {
  logoUrl: string;
  transparentLogoUrl?: string;
  sellerName: string | null;
  marketplace: Marketplace;
  isKnownSeller: boolean;
}

// TODO just delete this file and use multiverse sale platform info stuff
export const frontendMarketplaceToMultiverseMarketplaceMap = ({
  marketplace,
}: {
  marketplace: Marketplace;
}): SalePlatform => {
  if (marketplace === 'ebay_auctions') {
    return 'ebay_jobs';
  }
  if (marketplace === 'myslabs') {
    return 'myslabs_jobs';
  }
  return marketplace;
};

export const marketplaceNames: Record<Marketplace, string> = {
  ebay_auctions: 'eBay',
  myslabs: 'MySlabs',
  pwcc: 'Fanatics Collect',
  goldin_auctions: 'Goldin Auctions',
  heritage: 'Heritage Auctions',
  pristine_auction: 'Pristine Auction',
  '90s_auctions': '90s Auctions',
  huggins_and_scott: 'Huggins & Scott',
  lelands: 'Lelands',
  love_of_the_game: 'Love of the Game',
  cardshq: 'CardsHQ',
};

export interface UntrackedCollectibleSale {
  listingId: string;
  auctionId?: number;
  auctionUrl: string;
  endDateTime: string;
  imageUrl: string;
  included: boolean;
  listingType: string;
  bestOfferEnabled: boolean;
  offerPrice: number;
  offerPriceMessage: string;
  offerPriceChecked: boolean;
  sellingPrice: number;
  title: string;
  collectibleType: CollectibleType;
  finalPrice: number;
}

export type ForSaleItemType = 'Auctions' | 'FixedPrice';
