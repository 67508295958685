import { memo } from 'react';
import { useSetVariationOptions, type SetVariationOption } from './useSetVariationOptions';
import { AutoComplete, AutoCompleteOption, AutoCompleteProps } from 'sci-ui-components/forms/AutoComplete/AutoComplete';
import useDebouncedValue from '@/sci-ui-components/hooks/useDebouncedValue';

export type SetVariationsAutoCompleteProps = Omit<AutoCompleteProps<SetVariationOption>, 'options' | 'makeOption'> & {
  setIds?: number[] | string[] | null;
  setNames?: string[] | null;
  sportIds?: number[] | string[] | null;
  sportNames?: string[] | null;
  playerIds?: number[] | string[] | null;
  playerNames?: string[] | null;
  setYears?: number[] | string[] | null;
  debounceTimeoutInMs?: number;
  sendSearchText?: boolean;
};

export const SetVariationsAutoComplete = memo(function SetVariationsAutoComplete({
  value,
  setIds,
  setNames,
  sportIds,
  sportNames,
  playerIds,
  playerNames,
  setYears,
  debounceTimeoutInMs = 500,
  sendSearchText,
  ...other
}: SetVariationsAutoCompleteProps) {
  const [debouncedSearchText] = useDebouncedValue(value && !!sendSearchText ? value : undefined, debounceTimeoutInMs);
  const { items } = useSetVariationOptions(
    {
      searchText: debouncedSearchText,
      setIds: setIds?.map((id) => String(id)),
      setNames,
      sportIds: sportIds?.map((id) => String(id)),
      sportNames,
      playerIds: playerIds?.map((id) => String(id)),
      playerNames,
      setYears: setYears?.map((id) => String(id)),
    },
    {
      keepPreviousData: true,
    }
  );
  return <AutoComplete<SetVariationOption> {...other} options={items} makeOption={makeOption} value={value} />;
});

function makeOption(data: SetVariationOption): AutoCompleteOption<SetVariationOption> {
  return {
    label: data.label,
    value: String(data.variation_id),
    key: data.label,
    data,
  };
}
