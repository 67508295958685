import { memo } from 'react';
import useSealedWaxBoxTypes, { SealedWaxBoxType } from './useSealedWaxBoxTypes';
import { AutoComplete, AutoCompleteOption, AutoCompleteProps } from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type SealedWaxBoxTypeAutoCompleteProps = Omit<AutoCompleteProps<SealedWaxBoxType>, 'makeOption' | 'options'> & {
  allowAny?: boolean;
};

export const SealedWaxBoxTypeAutoComplete = memo(function SealedWaxBoxTypeAutoComplete({
  allowAny,
  ...other
}: SealedWaxBoxTypeAutoCompleteProps) {
  const { data = [] } = useSealedWaxBoxTypes({
    allowAny,
  });

  return <AutoComplete {...other} options={data} makeOption={makeOption} />;
});

function makeOption(data: SealedWaxBoxType): AutoCompleteOption<SealedWaxBoxType> {
  return {
    label: data.name,
    value: data.name,
    key: data.id.toString(),
    data,
  };
}
