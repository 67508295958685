import { FavoriteCategory } from '../../../../sci-ui-components/types/favoriteCategory';
import { ApiFavoriteCategory } from '../../types';

export default function favoritesCategoryFromApi(apiItem: ApiFavoriteCategory): FavoriteCategory {
  return {
    id: apiItem.id,
    name: apiItem.name,
    totalItems: typeof apiItem.count === 'string' ? Number.parseInt(apiItem.count, 10) : apiItem.count,
  };
}
