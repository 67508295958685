import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiFavoriteCategory } from '../types';
import { getApiCollectibleType } from './utils';

export interface AddFavoriteCollectibleParams {
  collectibleType: CollectibleType;
  categoryId: number;
  collectibleId: number;
}

export async function addFavoriteCollectible({
  collectibleType,
  categoryId,
  collectibleId,
}: AddFavoriteCollectibleParams) {
  const type = getApiCollectibleType(collectibleType);
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite', {
    method: 'POST',
    body: JSON.stringify({
      category_id: categoryId,
      foreign_id: collectibleId,
      type,
    }),
  });
}

export interface MoveFavoriteCollectibleToCategoryParams {
  categoryId: number;
  favoriteId: number;
}

export async function moveFavoriteCollectibleToCategory({
  categoryId,
  favoriteId,
}: MoveFavoriteCollectibleToCategoryParams) {
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>(`/favorites/${favoriteId}`, {
    method: 'PUT',
    body: JSON.stringify({
      category_id: categoryId,
    }),
  });
}

export interface RemoveCollectibleFromFavoritesParams {
  favoriteIds: number[];
}

export async function removeCollectibleFromFavorites({ favoriteIds }: RemoveCollectibleFromFavoritesParams) {
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite', {
    method: 'DELETE',
    body: JSON.stringify({
      favorites: favoriteIds,
    }),
  });
}
