import {
  CollectibleHistoricalStats,
  CollectibleRangeStats,
} from '../../../../sci-ui-components/types/collectibleHistoricalStats';
import { CollectibleType, isSportsCardCollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { ApiSealedWaxHistoricalStats, ApiSportsCardHistoricalStats, ApiHistoricalStats } from '../../types';

export default function collectibleHistoricalStatsFromApi(
  apiHistoricalStats: ApiHistoricalStats,
  collectibleType: CollectibleType
): CollectibleHistoricalStats {
  return {
    collectibleId: isSportsCardCollectibleType(collectibleType)
      ? (apiHistoricalStats as ApiSportsCardHistoricalStats).card_id
      : (apiHistoricalStats as ApiSealedWaxHistoricalStats).sealed_wax_id,
    createdAt: apiHistoricalStats.created_at,
    updatedAt: apiHistoricalStats.updated_at,
    firstSale: apiHistoricalStats.first_sale,
    lastSale: apiHistoricalStats.last_sale,
    all: getRangeByPrefix(apiHistoricalStats, 'all'),
    last365: getRangeByPrefix(apiHistoricalStats, 'last365'),
    last180: getRangeByPrefix(apiHistoricalStats, 'last180'),
    last90: getRangeByPrefix(apiHistoricalStats, 'last90'),
    last60: getRangeByPrefix(apiHistoricalStats, 'last60'),
    last30: getRangeByPrefix(apiHistoricalStats, 'last30'),
    last14: getRangeByPrefix(apiHistoricalStats, 'last14'),
    last7: getRangeByPrefix(apiHistoricalStats, 'last7'),
  };
}

function getRangeByPrefix(
  apiStats: ApiHistoricalStats,
  prefix: 'all' | 'last14' | 'last180' | 'last30' | 'last365' | 'last60' | 'last7' | 'last90'
): CollectibleRangeStats {
  const lastSale = apiStats.last_sale;
  const startAvg = apiStats[`${prefix}_start_avg`];
  const endAvg = apiStats[`${prefix}_end_avg`];
  const change = endAvg - startAvg;
  const changePercentage = startAvg !== 0 ? (100 * change) / startAvg : 0;
  const count = apiStats[`${prefix}_count`];
  return {
    avg: apiStats[`${prefix}_avg`],
    count: typeof count === 'string' ? Number(count) : count,
    max: apiStats[`${prefix}_max`],
    min: apiStats[`${prefix}_min`],
    total: apiStats[`${prefix}_total`],
    lastSale,
    startAvg,
    endAvg,
    change,
    changePercentage,
  };
}
