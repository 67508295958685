import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { CollectibleType } from '@sportscardinvestor/schemas';
import CollectibleImage from '../../sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import getSellerInfo from '../../services/sciApi/sales/transformers/getSellerInfo';
import { CurrentPriceInfo } from '../collectibles/CollectibleItem';
import type { RawCompletedSaleSearchItem } from './useRawCompletedSalesSearch';
import MarketplaceLogo from '@/sci-ui-components/sales/MarketplaceLogo/MarketplaceLogo';
import { listingTypeNames } from '@/features/admin/sales/completed/constants';

export interface SalesHistoryItemProps {
  item: RawCompletedSaleSearchItem;
  collectibleType: CollectibleType;
  className?: string;
  size?: SalesHistoryItemSize;
  rightContent?: ReactNode;
  showListingType?: boolean;
}
export type SalesHistoryItemSize = 'md' | 'lg';

export function SalesHistoryItem({
  item,
  collectibleType,
  className,
  size = 'md',
  rightContent,
  showListingType,
}: SalesHistoryItemProps) {
  const listingTypeName = item?.listingType ? listingTypeNames[item?.listingType] : null;

  return (
    <div
      className={clsx(
        'twp w-full flex',
        {
          'gap-3': size === 'md',
          'gap-4': size === 'lg',
        },
        className
      )}
    >
      <CollectibleImage
        className={clsx('grow-0 shrink-0', {
          'w-20': size === 'md',
          'w-28': size === 'lg',
        })}
        url={item.imageUrls[0]}
        collectibleType={collectibleType}
        alt={item.displayTitle}
      />
      <div className="grow shrink flex flex-col items-start justify-center gap-1">
        <h4 className={clsx('w-full line-clamp-3 text-start font-semibold text-base')}>{item.displayTitle}</h4>
        <div className="w-full flex items-center justify-start gap-4 flex-wrap">
          <CurrentPriceInfo currentPrice={item.finalPrice} currentPriceDate={item.saleDate} size={size} />
          <MarketplaceLogo {...getSellerInfo(item.platformId, null)} size="sm" />
          {!!showListingType && !!listingTypeName && <span>{listingTypeName}</span>}
        </div>
      </div>
      {rightContent}
    </div>
  );
}
