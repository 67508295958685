import React from 'react';
import clsx from 'clsx';
import classes from './ManualIndicator.module.scss';

interface Props {
  isManual?: boolean | null;
  className?: string;
}

export default function ManualIndicator({ isManual = false, className }: Props) {
  if (!isManual) return null;

  return (
    <span className={clsx(classes.root, className)} title="This is a manual collectible">
      M
    </span>
  );
}
