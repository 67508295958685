import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import { getCollectibleSportName } from '@sportscardinvestor/collectible-helpers';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { SportNameSelect } from '@/features/sports/SportNameSelect';

export const sportFormSchema = z.object({
  sportName: z.string().trim().default(''),
});

type SportFormSchema = z.input<typeof sportFormSchema>;
type SportFormPayload = z.output<typeof sportFormSchema>;

export function SportField({ className }: { className?: string }) {
  const form = useFormContext<SportFormSchema, unknown, SportFormPayload>();

  return (
    <Controller
      name="sportName"
      control={form.control}
      render={({ field, fieldState }) => (
        <FieldLabelBox
          className={className}
          label="Category"
          fieldId="sportName"
          error={fieldState.error?.message}
          variant="neater"
        >
          <SportNameSelect
            id="sportName"
            value={field.value}
            onSelect={(data) => {
              form.setValue('sportName', data?.name ?? '');
            }}
            allowOther
          />
        </FieldLabelBox>
      )}
    />
  );
}

export function getSportInitialValues({ collectible }: { collectible: CustomCollectible | null }): SportFormSchema {
  if (!collectible) {
    return {
      sportName: '',
    };
  }
  return {
    sportName: getCollectibleSportName(collectible) ?? '',
  };
}
