import { useQueryClient } from 'react-query';
import {
  useAuthenticatedMMAPIQuery,
  MmApiInput,
  MmApiOutput,
  MmApiQueryOptions,
  mmApiClient,
  useAuthenticatedMMApiMutation,
} from '../../services/mmApiX/index';
import useAuth from '../auth/useAuth';
import { showError, showSuccess } from 'services/toaster';

export type UseUserSettingsInput = Exclude<MmApiInput['private']['settings']['getUserSettings'], void>;
export type UseUserSettingsOutput = MmApiOutput['private']['settings']['getUserSettings'];
export type UserGlobalSettings = Exclude<UseUserSettingsOutput['globalSettings'], void | null>;
export type UserAdminSettings = Exclude<UseUserSettingsOutput['adminSettings'], void | null>;
export type UpdateUserSettingsInput = Exclude<MmApiInput['private']['settings']['updateUserSettings'], void>;

export const useUserSettingsKey = 'private.settings.getUserSettings';
type QueryKey = [typeof useUserSettingsKey];

export const currencyUpdateSuccesMessage = 'Currency preference updated';

export default function useUserSettings(options: MmApiQueryOptions<UseUserSettingsOutput, QueryKey> = {}) {
  const { isLoggedIn } = useAuth();
  const queryKey: QueryKey = [useUserSettingsKey];
  const result = useAuthenticatedMMAPIQuery(queryKey, () => mmApiClient.private.settings.getUserSettings.query(), {
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    ...options,
    enabled: !!isLoggedIn && (options?.enabled ?? true),
  });
  const queryClient = useQueryClient();

  const updateMutation = useAuthenticatedMMApiMutation(
    (
      input: UpdateUserSettingsInput & {
        successMessage?: string;
      }
    ) => mmApiClient.private.settings.updateUserSettings.mutate(input),
    {
      onSuccess: (newSettings, { successMessage }) => {
        queryClient.setQueryData(queryKey, newSettings);
        if (successMessage) {
          showSuccess({
            message: successMessage,
          });
        }
      },
      onError: (err) => {
        showError({
          message: 'Failed to update user settings. Please try again.',
          description: err.message,
        });
      },
    }
  );

  const selectedCurrencyCode = result?.data?.globalSettings?.preferences?.currencyCode ?? 'USD';

  return {
    ...result,
    updateMutation,
    selectedCurrencyCode,
  };
}
