import { ChartColors } from '../../../../sci-ui-components/styles/chartColors';
import { CompletedSaleListingType } from './autoExcluded/useAutoExcludedCompletedSalesList';

export const listingTypeNames: Record<CompletedSaleListingType, string> = {
  Auction: 'Auction',
  AuctionWithBIN: 'Auction with BIN',
  BestOffer: 'Best Offer',
  Fixed: 'Fixed Price',
  FixedPrice: 'Fixed Price',
  StoreInventory: 'Store Inventory',
  Unspecified: 'Unspecified',
  Store: 'Store',
};

export const adminChartColorsByDataSource: {
  [key: string]: ChartColors;
} = {
  goldin_auctions: ChartColors.L3,
  heritage: ChartColors.L9,
  pwcc: ChartColors.L6,
  ebay_jobs: ChartColors.L5,
  myslabs_jobs: ChartColors.L10,
  '90s_auctions': ChartColors.L7,
  huggins_and_scott: ChartColors.L8,
  lelands: ChartColors.L11,
  love_of_the_game: ChartColors.L12,
  cardshq: ChartColors.L1,
};
