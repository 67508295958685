import { ReactNode } from 'react';
import { StatSignDisplay } from '../types/statSignDisplay';
import { StatType } from '../types/statType';
import { ConvertPriceFn } from 'hooks/useCurrencyFormatter';

export type FormattableType = string | number | undefined | null;

export function isFormattableType(value: string | number | undefined | null | ReactNode): value is FormattableType {
  return typeof value === 'string' || typeof value === 'number' || value === null || value === undefined;
}

export interface FormatStatValueParams {
  value: FormattableType;
  type: StatType;
  defaultValue?: string;
  signDisplay?: StatSignDisplay;
  formatOverrides?: {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    maximumSignificantDigits?: number;
    notation?: Intl.NumberFormatOptions['notation'];
  };
  treatZeroAsNull?: boolean;
  currencyCode?: string;
  convertPriceFromUSD?: ConvertPriceFn;
}

export type FormatStatValueFn = (params: FormatStatValueParams) => string;

/**
 * formats value in a consistent way based on value type
 * @param value stat value
 * @param type stat type
 * @returns string
 */
export default function formatStatValue({
  value,
  type,
  defaultValue = '--',
  treatZeroAsNull,
  ...other
}: FormatStatValueParams) {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value !== 'number') {
    return defaultValue;
  }
  if (treatZeroAsNull && value === 0) {
    return defaultValue;
  }
  switch (type) {
    case 'price':
      return formatPriceValue({
        value,
        ...other,
      });
    case 'percentage':
      return formatPercentageValue({
        value,
        ...other,
      });
    case 'count':
      return formatCountValue({
        value,
        ...other,
      });
    default:
      return new Intl.NumberFormat('en-US', other).format(value);
  }
}

type FormatParams = Omit<FormatStatValueParams, 'type' | 'defaultValue' | 'value'> & {
  value: number;
};

/**
 * returns formated price value
 *
 * x < $100, display 2 decimals no matter what.
 *   $33.00
 *   $33.53
 * $100 <= x < $1000 , no decimals. Round to whole integers.
 *   $101.49 = $101
 *   $101.50 = $102
 * $1000 <= x < $10000 , use the 'k' abbreviation with two decimal places
 *   $1.05k
 *   $9.43k
 * $10000 <= x , use 'k' and only one decimal place
 *   $10.1k
 *   $99.0k
 *
 * @param value The stat value as a number.
 * @returns string
 */
function formatPriceValue({
  value: valueBase,
  formatOverrides,
  convertPriceFromUSD,
  currencyCode = 'USD',
  signDisplay,
}: FormatParams): string {
  const commonParams: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currencyCode,
    signDisplay: signDisplay,
    currencyDisplay: 'narrowSymbol',
  };
  let value = valueBase;
  if (convertPriceFromUSD) {
    value = convertPriceFromUSD({ value: valueBase }) as number;
  }
  const absoluteValue = Math.abs(value);
  let result = '';
  if (absoluteValue < 100) {
    result = new Intl.NumberFormat('en-US', {
      ...commonParams,
      ...(formatOverrides
        ? formatOverrides
        : {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            notation: 'standard',
          }),
    }).format(value);
  } else if (absoluteValue < 1000) {
    result = new Intl.NumberFormat('en-US', {
      ...commonParams,
      ...(formatOverrides
        ? formatOverrides
        : {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            notation: 'standard',
          }),
    }).format(value);
  } else if (absoluteValue < 10000) {
    result = new Intl.NumberFormat('en-US', {
      ...commonParams,
      ...(formatOverrides
        ? formatOverrides
        : {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            notation: 'compact',
          }),
    }).format(value);
  } else {
    result = new Intl.NumberFormat('en-US', {
      ...commonParams,
      ...(formatOverrides
        ? formatOverrides
        : {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            notation: 'compact',
          }),
    }).format(value);
  }
  if (currencyCode === 'EUR') {
    return result.replace('€', '') + ' €';
  }
  return result;
}

/**
 * returns formatted percentage value
 *
 * x <100, display 1 decimal no matter what.
 *   33.0%
 *   33.5%
 * 100 <= x < 10000 , no decimals. Round to whole integers.
 *   101%
 *   102%
 * 10000 <= x , 9999%
 *
 * @param value The stat value as a number.
 * @returns string
 */
function formatPercentageValue({ value, formatOverrides, signDisplay }: FormatParams): string {
  const commonParams: Intl.NumberFormatOptions = {
    style: 'percent',
    signDisplay: signDisplay,
  };
  const absoluteValue = Math.abs(value);
  if (absoluteValue < 100) {
    return new Intl.NumberFormat('en-US', {
      ...commonParams,
      ...(formatOverrides
        ? formatOverrides
        : {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            notation: 'standard',
          }),
    }).format(value / 100);
  }
  if (absoluteValue < 10000) {
    return new Intl.NumberFormat('en-US', {
      ...commonParams,
      ...(formatOverrides
        ? formatOverrides
        : {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            notation: 'standard',
          }),
    }).format(value / 100);
  }
  return new Intl.NumberFormat('en-US', {
    ...commonParams,
    ...(formatOverrides
      ? formatOverrides
      : {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          notation: 'standard',
        }),
  }).format(99.99);
}

/**
 * returns formatted count value
 *
 * x < 1000, 0 or 1 decimal places
 * 100
 * 100.3
 * 1000 <= x, use 'k' and one decimal place
 * 2.4k
 * 333.5k
 *
 * @param value The stat value as a number.
 * @returns string
 */
function formatCountValue({ value, formatOverrides, signDisplay }: FormatParams): string {
  const commonParams: Intl.NumberFormatOptions = {
    signDisplay: signDisplay,
  };
  const absoluteValue = Math.abs(value);
  if (absoluteValue < 1000) {
    return new Intl.NumberFormat('en-US', {
      ...commonParams,
      ...(formatOverrides
        ? formatOverrides
        : {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
            notation: 'standard',
          }),
    }).format(value);
  }
  return new Intl.NumberFormat('en-US', {
    ...commonParams,
    ...(formatOverrides
      ? formatOverrides
      : {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
          notation: 'compact',
        }),
  }).format(value);
}
