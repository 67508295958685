export function formatter(
  value: number | string | null | undefined,
  minimumFractionDigits: number | undefined = undefined,
  maximumFractionDigits: number | undefined = undefined
): string {
  return typeof value !== 'undefined' && value !== '' && value !== null
    ? new Intl.NumberFormat('en-US', {
        notation: 'standard',
        minimumFractionDigits: minimumFractionDigits ?? undefined,
        maximumFractionDigits: maximumFractionDigits ?? undefined,
      }).format(Number(value))
    : '';
}

export function parser(value: string | null | undefined): number | undefined {
  if (typeof value === 'undefined' || value === null || value === '') {
    return undefined;
  }
  const withoutSeparators = (value || '0').replace(/\$\s?|(,*)/g, '');
  return withoutSeparators.includes('.')
    ? Number.parseFloat(withoutSeparators)
    : Number.parseInt(withoutSeparators, 10);
}

export function formatterInteger(value: number | string | null | undefined): string {
  return typeof value !== 'undefined' && value !== '' && value !== null
    ? new Intl.NumberFormat('en-US', {
        notation: 'standard',
        maximumFractionDigits: 0,
      }).format(Number(value))
    : '';
}

export function parserInteger(value: string | null | undefined): number | undefined {
  if (typeof value === 'undefined' || value === null || value === '') {
    return undefined;
  }
  const flooredValue = (value || '0').replace(/\..*$/g, '');
  return parser(flooredValue);
}

export const parseStringToNumber = (string: string, radix: number = 100) => {
  return Math.round(Number(string) * radix) / radix;
};
