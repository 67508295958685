import { memo } from 'react';
import { AutoComplete, AutoCompleteOption, AutoCompleteProps } from 'sci-ui-components/forms/AutoComplete/AutoComplete';
import { CardGrade } from 'sci-ui-components/types/cardGrade';
import useCardGrades from 'features/cardGrades/useCardGrades';

export type CardGradesAutoCompleteProps = Omit<AutoCompleteProps<CardGrade>, 'options' | 'makeOption'>;

export const CardGradesAutoComplete = memo(function CardGradesAutoComplete(props: CardGradesAutoCompleteProps) {
  const { data = [] } = useCardGrades({});
  return <AutoComplete {...props} options={data} makeOption={makeOption} />;
});

function makeOption(data: CardGrade): AutoCompleteOption<CardGrade> {
  return {
    label: data.name,
    value: data.name,
    key: data.id.toString(),
    data,
  };
}
