import createDialogSingleton, { DialogProviderProps } from '../utils/createDialogSingleton';
import { ConfirmationDialogInfoProps } from './types';

const { invokeDialog: waitForConfirmation, registerDialog } = createDialogSingleton<
  ConfirmationDialogInfoProps,
  boolean
>();

export { waitForConfirmation, registerDialog };
export type ConfirmationDialogProviderProps = DialogProviderProps<ConfirmationDialogInfoProps, boolean>;
