import qs from 'qs';
import { ApiSport } from '../types';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import sportFromApi from '../collectibles/transformers/sportFromApi';

export interface GetSportsParams {
  offset: number;
  limit?: number;
  ids?: number[];
}

export async function getSports({ limit, offset, ids }: GetSportsParams, signal: AbortSignal | undefined) {
  const apiItems = await authenticatedFetchFromSciApi<ApiSport[]>(
    `/sports${qs.stringify(
      {
        id: ids,
        limit,
        skip: offset,
      },
      {
        skipNulls: true,
        addQueryPrefix: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );
  return apiItems.map(sportFromApi);
}
