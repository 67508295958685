import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiSealedWaxBoxType } from '../types';
import sealedWaxBoxtypeFromApi from '../collectibles/transformers/sealedWaxBoxtypeFromApi';

export async function getSealedWaxBoxTypes(signal: AbortSignal | undefined) {
  const apiItems = await authenticatedFetchFromSciApi<ApiSealedWaxBoxType[]>('/box-types', {
    method: 'GET',
    signal,
  });
  return apiItems?.map(sealedWaxBoxtypeFromApi);
}
