import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';
import {
  mmApiClient,
  MmApiInput,
  MmApiOutput,
  MmApiInfiniteQueryOptions,
  useAuthenticatedMMAPIInfiniteQuery,
} from '@/services/mmApiX';

export type ListFavoriteCollectiblesInput = Exclude<MmApiInput['private']['favorites']['collectibles']['list'], void>;
export type ListFavoriteCollectiblesOutput = Exclude<MmApiOutput['private']['favorites']['collectibles']['list'], void>;
export type ListFavoriteCollectibleOutputItem = ListFavoriteCollectiblesOutput['items'][number];

export const listFavoriteCollectiblesQueryPrefix = 'private.favorites.collectibles.list' as const;

type ListFavoriteCollectiblesQueryKey = [
  typeof listFavoriteCollectiblesQueryPrefix,
  ListFavoriteCollectiblesInput['filters']['collectibleType'],
  ListFavoriteCollectiblesInput
];

export const getBaseListFavoriteCollectiblesQueryKey = (collectibleType: CollectibleType) =>
  [listFavoriteCollectiblesQueryPrefix, collectibleType] as const;

const PAGE_SIZE = 10;

export default function useListFavoriteCollectiblesInfinite(
  { limit = PAGE_SIZE, ...restOfParams }: ListFavoriteCollectiblesInput,
  options?: MmApiInfiniteQueryOptions<ListFavoriteCollectiblesOutput, ListFavoriteCollectiblesQueryKey>
) {
  const params = { ...restOfParams, limit };

  const queryKey: ListFavoriteCollectiblesQueryKey = [
    listFavoriteCollectiblesQueryPrefix,
    params.filters.collectibleType,
    params,
  ];
  const { data, ...queryResult } = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    ({ pageParam = 0 }) => mmApiClient.private.favorites.collectibles.list.query({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const currentCount = allPages.reduce((acc, page) => acc + page.items.length, 0);
        if (lastPage?.items?.length >= limit) {
          return currentCount;
        }
        return undefined;
      },
    }
  );

  const allItems = useMemo(() => uniqBy(data?.pages?.map(({ items }) => items).flat() ?? [], 'id'), [data]);

  return {
    ...queryResult,
    allItems,
  };
}
