import { CardSet } from '../../../../sci-ui-components/types/cardSet';
import { ApiCardSet } from '../../types';
import sportFromApi from './sportFromApi';

export default function cardSetFromApi(apiSet: ApiCardSet): CardSet {
  const sport = apiSet.sport ? sportFromApi(apiSet.sport) : null;
  return {
    activeFrequencyTier: apiSet.active_frequency_tier,
    frequencyTier: apiSet.frequency_tier,
    id: apiSet.id,
    jobMasterSetId: apiSet.job_master_set_id,
    lastSyncedAt: apiSet.last_synced_at,
    lastLiveSyncedAt: apiSet.last_live_synced_at,
    name: apiSet.name,
    query: apiSet.query !== 'FOR WAX USE ONLY' ? apiSet.query : apiSet.name,
    sport,
    sportId: apiSet.sport_id,
    waxQuery: apiSet.wax_query,
    year: apiSet.year,
    // NOTE: api returns inconsistent data in "name" field. Sometimes it is name and sometimes it is concatenated info
    displayText: apiSet.name?.includes(apiSet.year)
      ? apiSet.name
      : [apiSet.year, apiSet.name, sport?.name].filter((v) => !!v).join(' '),
  };
}
