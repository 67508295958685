import type { CustomCollectible } from '@sportscardinvestor/schemas';
import type {
  CustomCardCollectible as OldCustomCollectible,
  CustomSealedWaxCollectible as OldCustomSealedWaxCollectible,
  CustomSportsCardCollectible as OldCustomSportsCardCollectible,
} from '../../../sci-ui-components/types/collectible';
import { CollectibleType } from '../constants';
import {
  temp_convertPlayerToOldPlayer,
  temp_convertSeToOldSet,
} from './temp_convertCollectibleToOldNonCustomCollectible';

export function temp_convertCustomCollectibleToOldCustomCollectible(item: CustomCollectible): OldCustomCollectible {
  if (item.collectibleType === 'sports-card') {
    const sportsCard: OldCustomSportsCardCollectible = {
      collectibleType: item.collectibleType as CollectibleType,
      createdAt: item.createdAt ?? '',
      createdById: null,
      currentPrice: item.price,
      customPhotos: null,
      customQuery: item.customQuery ?? null,
      id: Number(item.id),
      imageUrl: item.imageUrl ?? null,
      isCustom: true,
      query: item.query ?? null,
      updatedAt: item.updatedAt,
      updatedById: null,
      currentPriceUpdatedAt: item.priceDate,
      cardNumber: item.cardNumber,
      cardSet: item.set ? temp_convertSeToOldSet(item.set) : null,
      customCardSetId: item.set ? Number(item.set.id) : null,
      customCardSetName: item.setName,
      customCardSetYear: item.setYear ? String(item.setYear) : null,
      gradeName: item.gradeName,
      isRookie: item.isRookie,
      player: item.player ? temp_convertPlayerToOldPlayer(item.player) : null,
      playerId: item.player ? Number(item.player.id) : null,
      playerName: item.playerName,
      populationCount: 0,
      populationLastUpdated: null,
      serialNumber: null,
      specificQualifier: item.specificQualifier,
      sportName: item.sportName,
      variationName: item.variationName,
    };
    return sportsCard;
  }
  if (item.collectibleType === 'sealed-wax-card') {
    const wax: OldCustomSealedWaxCollectible = {
      collectibleType: item.collectibleType as CollectibleType,
      createdAt: item.createdAt ?? '',
      createdById: null,
      currentPrice: item.price,
      customPhotos: null,
      customQuery: item.customQuery ?? null,
      id: Number(item.id),
      imageUrl: item.imageUrl ?? null,
      isCustom: true,
      query: item.query ?? null,
      updatedAt: item.updatedAt,
      updatedById: null,
      currentPriceUpdatedAt: item.priceDate,
      cardSet: item.set ? temp_convertSeToOldSet(item.set) : null,
      boxTypeName: item.boxTypeName,
      customCardSetId: item.set ? Number(item.set.id) : null,
      customCardSetName: item.setName,
      customCardSetYear: item.setYear ? String(item.setYear) : null,
      sportName: item.sportName,
    };
    return wax;
  }
  throw new Error('Unexpected collectible-type');
}
