export enum ChartColors {
  L1 = '#0C3EE2',
  L2 = '#05b8f2',
  L3 = '#FFC107',
  L4 = '#D81B60',
  L5 = '#33F5A4',
  L6 = '#000000',
  L7 = '#ffa300',
  L8 = '#dc0ab4',
  L9 = '#b3d4ff',
  L10 = '#F40000',
  L11 = '#F397D6',
  L12 = '#0600FF',
  L13 = '#FFA500',
  Failure = '#FF0000',
}

export const chartColorsList = Object.values(ChartColors);
