import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { marketplaceNames, SellerInfo } from '../../types/sales';

import classes from './MarketplaceLogo.module.scss';

export type MarketplaceLogoProps = SellerInfo & {
  displaySellerName?: boolean;
  size?: 'md' | 'sm';
} & DetailedHTMLProps<HTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function MarketplaceLogo({
  className,
  logoUrl,
  marketplace,
  sellerName,
  displaySellerName = false,
  isKnownSeller,
  size = 'md',
  ...otherProps
}: MarketplaceLogoProps) {
  return (
    <label className={clsx(classes.root, className)}>
      <img
        {...otherProps}
        alt={`${marketplaceNames[marketplace]} ${sellerName ?? ''}`}
        src={logoUrl ?? '/icons/platforms/ebay.png'}
        className={clsx(classes.image, {
          [classes.md]: size === 'md',
          [classes.sm]: size === 'sm',
        })}
        loading="lazy"
      />
      {!!displaySellerName && !!sellerName && !isKnownSeller && <span className={classes.name}>{sellerName}</span>}
    </label>
  );
}
