import { Player } from '../../../../sci-ui-components/types/player';
import { ApiPlayer } from '../../types';
import sportFromApi from './sportFromApi';

export default function playerFromApi(apiPlayer: ApiPlayer): Player {
  return {
    id: apiPlayer.id,
    name: apiPlayer.name,
    alias: apiPlayer.alias,
    query: apiPlayer.query,
    rookieYears: apiPlayer.rookie_years?.map((y) => (typeof y === 'string' ? Number.parseInt(y, 10) : y)) ?? [],
    sport: apiPlayer.sport ? sportFromApi(apiPlayer.sport) : null,
    sportId: apiPlayer.sport_id,
  };
}
