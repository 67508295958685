import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';

import classes from './NoticeText.module.scss';

export type NoticeTextProps = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  size?: Size;
};

export type Size = 'large' | 'medium';

export default function NoticeText({ className, size = 'large', ...other }: NoticeTextProps) {
  return (
    <p
      {...other}
      className={clsx(
        classes.root,
        { [classes.large]: size === 'large', [classes.medium]: size === 'medium' },
        className
      )}
    />
  );
}
