import React, { ReactNode, Children, ReactElement, useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import classes from './VerticalTilesList.module.scss';
import NoticeText from 'sci-ui-components/NoticeText/NoticeText';
import Button from 'sci-ui-components/buttons/Button/Button';
import LoadingSpinner from 'sci-ui-components/LoadingSpinner/LoadingSpinner';
import useStableFunctionIdentity from 'sci-ui-components/hooks/useStableFunctionIdentity';

export interface VerticalTilesListProps {
  className?: string;
  listClassName?: string;
  children: ReactElement | ReactElement[];
  endApproachThreshold?: number;
  onApproachingEnd?: () => void;
  itemVisibilityThreshold?: number;
  gap?: number;
  isLoading?: boolean;
  emptyMessage?: ReactNode;
  onLoadMoreClick?: () => void;
  canLoadMore?: boolean;
}

export default function VerticalTilesList({
  className,
  listClassName,
  children,
  onApproachingEnd,
  gap = 0,
  isLoading = false,
  emptyMessage = 'No results found',
  onLoadMoreClick,
  canLoadMore = false,
  endApproachThreshold = 0,
}: VerticalTilesListProps) {
  const lastItemIndex = Children.count(children) - 1;

  const [lastItemElement, setLastItemElement] = useState<HTMLLIElement | null>(null);
  const observerCallback = useStableFunctionIdentity<IntersectionObserverCallback>((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        onApproachingEnd?.();
      }
    });
  });
  const observer = useMemo(() => new IntersectionObserver(observerCallback), [observerCallback]);

  useEffect(() => {
    if (!lastItemElement) {
      return () => {};
    }
    observer.observe(lastItemElement);
    return () => observer.unobserve(lastItemElement);
  }, [lastItemElement, observer]);

  return (
    <div className={clsx(classes.root, className)}>
      <ul className={clsx(classes.container, listClassName)}>
        {!isLoading && Children.count(children) < 1 && (
          <li className={classes.item}>
            <NoticeText className={classes.emptyMessage}>{emptyMessage}</NoticeText>
          </li>
        )}
        {Children.map(children, (child, itemIndex) => {
          return child !== null ? (
            <li
              key={child.key}
              className={classes.item}
              ref={
                itemIndex === Math.max(lastItemIndex - endApproachThreshold, 0)
                  ? (el) => setLastItemElement(el)
                  : undefined
              }
            >
              {child}
              {!!(lastItemIndex !== itemIndex) && <div style={{ height: gap }} />}
            </li>
          ) : null;
        })}
        {isLoading && (
          <li key="loading" className={clsx(classes.item, classes.loadingContainer)}>
            <LoadingSpinner />
          </li>
        )}
        {!!onLoadMoreClick && !isLoading && canLoadMore && (
          <li className={classes.item}>
            <Button
              size="small"
              type="primary"
              color="primary"
              onClick={onLoadMoreClick}
              className={classes.loadMoreButton}
            >
              Load more
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
}
