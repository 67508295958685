import { CollectibleType } from '@sportscardinvestor/schemas';
import { EditCollectionItemPurchaseDetailsInitialValues } from '../EditCollectionItemPurchaseDetailsDialog/EditCollectionItemPurchaseDetailsForm';
import { EditCustomCollectionItemFormInitialValues } from '../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
import { EditCustomCollectibleValueFormInitialValues } from '../EditCustomCollectibleValueDialog/EditCustomCollectibleValueForm';
import { addCollectionItem } from './store';

export { addCollectionItem, type AddCollectionItemProps } from './store';
export type { EditCollectionItemPurchaseDetailsInitialValues } from '../EditCollectionItemPurchaseDetailsDialog/EditCollectionItemPurchaseDetailsForm';
export type { EditCustomCollectionItemFormInitialValues } from '../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
export type { EditCustomCollectibleValueFormInitialValues } from '../EditCustomCollectibleValueDialog/EditCustomCollectibleValueForm';

export interface AddFeaturedCollectibleToCollectionProps {
  collectibleType: CollectibleType;
  collectibleId: number;
  initialPurchaseDetails: EditCollectionItemPurchaseDetailsInitialValues | null;
}
export function addFeaturedCollectibleToCollection({
  collectibleId,
  ...rest
}: AddFeaturedCollectibleToCollectionProps) {
  return addCollectionItem({
    ...rest,
    featuredCollectibleId: collectibleId,
    initialCustomCollectibleInfo: null,
    initialCustomCollectibleValueInfo: null,
    initialSearchText: null,
  });
}

export interface AddCustomCollectibleToCollectionProps {
  collectibleType: CollectibleType;
  initialCustomCollectibleInfo: EditCustomCollectionItemFormInitialValues;
  initialCustomCollectibleValueInfo: EditCustomCollectibleValueFormInitialValues | null;
  initialPurchaseDetails: EditCollectionItemPurchaseDetailsInitialValues | null;
}
export function addCustomCollectibleToCollection(params: AddCustomCollectibleToCollectionProps) {
  return addCollectionItem({
    ...params,
    featuredCollectibleId: null,
    initialSearchText: null,
  });
}
