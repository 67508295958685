import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { PopulationCountChartDialogProps } from './types';

const { invokeDialog: chartPopulationCount, registerDialog } = createDialogSingleton<
  PopulationCountChartDialogProps,
  boolean
>();

export { chartPopulationCount, registerDialog };
export type PopulationCountChartDialogProviderProps = DialogProviderProps<PopulationCountChartDialogProps, boolean>;
