import { memo, useMemo } from 'react';
import { useSports, Sport } from 'features/sports/useSports';
import { AutoComplete, AutoCompleteOption, AutoCompleteProps } from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type SportsAutoCompleteProps = Omit<AutoCompleteProps<Sport>, 'makeOption' | 'options'> & {
  allowOther?: boolean;
};

export const otherSportOption = {
  id: -1,
  name: 'Other',
  query: null,
} as const;

export const SportsAutoComplete = memo(function SportsAutoComplete({ allowOther, ...rest }: SportsAutoCompleteProps) {
  const { data = [] } = useSports({});
  const options = useMemo<Sport[]>(
    () => (data?.length && allowOther ? [...data, otherSportOption] : data),
    [data, allowOther]
  );

  return <AutoComplete {...rest} options={options} makeOption={makeOption} />;
});

function makeOption(data: Sport): AutoCompleteOption<Sport> {
  return {
    label: data.name,
    value: data.name,
    key: data.id.toString(),
    data,
  };
}
