import { CustomCollectible } from '@sportscardinvestor/schemas';
import { isSealedCustomCollectible } from '@sportscardinvestor/collectible-helpers';
import {
  MmApiInput,
  MmApiOutput,
  mmApiClient,
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
} from '../../services/mmApiX/index';

export type UseGeneratedCollectibleQueryInput = MmApiInput['private']['collectibles']['getGeneratedQuery'];
export type UseGeneratedCollectibleQueryOutput = MmApiOutput['private']['collectibles']['getGeneratedQuery'];

export const useGeneratedCollectibleQueryKeyPrefix = 'private.collectibles.getGeneratedQuery';
type QueryKey = [typeof useGeneratedCollectibleQueryKeyPrefix, UseGeneratedCollectibleQueryInput];

export function useGeneratedCollectibleQuery(
  input: UseGeneratedCollectibleQueryInput,
  options: MmApiQueryOptions<UseGeneratedCollectibleQueryOutput, QueryKey> = {}
) {
  const queryKey: QueryKey = [useGeneratedCollectibleQueryKeyPrefix, input];

  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    () => mmApiClient.private.collectibles.getGeneratedQuery.query(input),
    {
      staleTime: 1000 * 60 * 60 * 6, // 6 hours
      ...options,
    }
  );

  return result;
}

export function useGeneratedCollectibleQueryForCustomCollectible(
  customCollectible: CustomCollectible | null,
  { enabled, ignoreExisting }: { enabled?: boolean; ignoreExisting?: boolean } = {}
) {
  const query = customCollectible?.query?.trim();
  const result = useGeneratedCollectibleQuery(getQueryInputFromCustomCollectible(customCollectible), {
    enabled: !!customCollectible && (!query?.length || ignoreExisting) && enabled !== false,
  });
  return {
    ...result,
    data: result.data ?? query ?? null,
  };
}

function getQueryInputFromCustomCollectible(
  customCollectible: CustomCollectible | null
): UseGeneratedCollectibleQueryInput {
  if (!customCollectible) {
    return {
      collectibleTypes: ['sports-card', 'sealed-wax-card'],
    };
  }
  if (isSealedCustomCollectible(customCollectible)) {
    return {
      collectibleTypes: [customCollectible.collectibleType],
      boxTypeName: customCollectible.boxTypeName,
      setName: customCollectible.setName,
      setId: customCollectible.set?.id ? Number(customCollectible.set?.id) : undefined,
      setQuery: customCollectible.set?.query,
      setYear: customCollectible?.setYear,
      sportName: customCollectible?.sportName,
    };
  }
  return {
    collectibleTypes: [customCollectible.collectibleType],
    gradeName: customCollectible.gradeName,
    setName: customCollectible.setName,
    setId: customCollectible.set?.id ? Number(customCollectible.set?.id) : undefined,
    setQuery: customCollectible.set?.query,
    setYear: customCollectible?.setYear,
    sportName: customCollectible?.sportName,
    cardNumber: customCollectible?.cardNumber,
    playerName: customCollectible.playerName,
    playerId: customCollectible.player?.id ? Number(customCollectible.player?.id) : undefined,
    playerQuery: customCollectible.player?.query,
    variationName: customCollectible.variationName,
    specificQualifier: customCollectible.specificQualifier,
  };
}
