import { AlternateCardGrade } from '../../../../sci-ui-components/types/cardGrade';
import { ApiAlternateCardGrade } from '../../types';
import collectibleHistoricalStatsFromApi from '../../stats/transformers/collectibleHistoricalStatsFromApi';

export default function alternateCardGradeFromApi(apiItem: ApiAlternateCardGrade): AlternateCardGrade {
  return {
    collectibleId: apiItem.card_id,
    gradeName: apiItem.grade.name,
    historicalStats: collectibleHistoricalStatsFromApi(apiItem.historical_stats, 'sports-card'),
  };
}
