import { CardGrade } from '../../../../sci-ui-components/types/cardGrade';
import { ApiGrade } from '../../types';

export default function cardGradeFromApi(apiGrade: ApiGrade): CardGrade {
  return {
    id: apiGrade.id,
    name: apiGrade.name,
    abbreviation: apiGrade.abbreviation,
    order: apiGrade.order,
    query: apiGrade.query,
  };
}
