import clsx from 'clsx';
import { Collectible, CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import {
  getCollectibleCurrentPrice,
  getCollectibleGradeName,
  getCollectibleImageUrl,
  getCollectiblePopulationCount,
  isCustomCollectible,
  isRookieSportsCard,
  makeCollectibleDescriptions,
} from '@sportscardinvestor/collectible-helpers';
import { CustomCollectiblePhotoSelector } from '../collection/CustomCollectiblePhotoSelector';
import RookieIndicator from '@/sci-ui-components/sport-cards/RookieIndicator/RookieIndicator';
import ManualIndicator from '@/sci-ui-components/collectibles/ManualIndicator/ManualIndicator';
import formatStatValue from '@/sci-ui-components/utils/formatStatValue';
import { formatDate, formatDateInTimeZone } from '@/sci-ui-components/utils/date';
import useFormatStatValue from '@/hooks/useFormatStatValue';
import CollectibleImage, {
  CollectibleImageSkeleton,
} from '@/sci-ui-components/collectibles/CollectibleImage/CollectibleImage';

export type CollectibleItemProps = CollectibleDescriptionProps & {
  collectibleType: CollectibleType;
  onImageChange?: ((newImageUrl: string | null) => void) | null;
};

export interface CustomPrice {
  currentPrice: number | null;
  currentPriceDate: string | null;
}

export function CollectibleItem({
  className,
  collectibleType,
  collectible,
  isLoading,
  size = 'md',
  onImageChange,
  customPrice,
}: CollectibleItemProps) {
  const imageUrl = collectible ? getCollectibleImageUrl(collectible) : null;
  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescriptions(collectible, {
    includePrintRun: false,
    includeGrade: false,
    includeRookie: false,
  });
  const isRookie = collectible ? isRookieSportsCard(collectible) : false;
  const isManual = collectible ? isCustomCollectible(collectible) : false;
  const populationCount = collectible ? getCollectiblePopulationCount(collectible) : null;
  const gradeName = collectible ? getCollectibleGradeName(collectible) : null;
  const currentPrice = collectible ? getCollectibleCurrentPrice(collectible) : null;

  return (
    <div
      className={clsx(
        'twp flex w-full flex-row items-stretch justify-start',
        {
          'gap-3': size === 'md',
          'gap-4': size === 'lg',
        },
        className
      )}
    >
      {!!isLoading && !imageUrl ? (
        <CollectibleImageSkeleton collectibleType={collectibleType} />
      ) : (
        <>
          {!!isManual && !!onImageChange ? (
            <CustomCollectiblePhotoSelector
              id="image"
              className={clsx('grow-0 shrink-0', {
                'w-20': size === 'md',
                'w-28': size === 'lg',
              })}
              value={imageUrl ?? null}
              collectibleType={collectibleType}
              onChange={(newImageUrl) => {
                if (newImageUrl) {
                  onImageChange(newImageUrl);
                }
              }}
              buttonsSize="xsmall"
            />
          ) : (
            <CollectibleImage
              url={imageUrl}
              collectibleType={collectibleType}
              className={clsx('grow-0 shrink-0', {
                'w-20': size === 'md',
                'w-28': size === 'lg',
              })}
              alt={descriptionLine1}
            />
          )}
        </>
      )}
      <CollectibleDescriptionUI
        className="grow shrink"
        title={title}
        subTitle={descriptionLine1}
        variationDescription={descriptionLine2}
        gradeName={gradeName}
        populationCount={populationCount}
        isLoading={isLoading}
        isManual={isManual}
        isRookie={isRookie}
        currentPrice={currentPrice?.currentPrice ? currentPrice?.currentPrice : customPrice?.currentPrice}
        currentPriceDate={currentPrice?.currentPrice ? currentPrice?.currentPriceDate : customPrice?.currentPriceDate}
        useLocalTimezone={isManual}
      />
    </div>
  );
}

export type CollectibleDescriptionProps = {
  className?: string;
  collectible: Collectible | CustomCollectible | null;
  isLoading?: boolean;
  /**
   * Users can have custom price defined for featured collectibles in their collection.
   * Those prices are not available on Collectible object as those are non user-specific
   */
  customPrice?: CustomPrice | null;
} & SizeProps;

export default function CollectibleDescription({
  collectible,
  className,
  isLoading,
  size,
  customPrice,
}: CollectibleDescriptionProps) {
  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescriptions(collectible, {
    includePrintRun: false,
    includeGrade: false,
    includeRookie: false,
  });
  const isRookie = collectible ? isRookieSportsCard(collectible) : null;
  const isManual = collectible ? isCustomCollectible(collectible) : null;
  const populationCount = collectible ? getCollectiblePopulationCount(collectible) : null;
  const gradeName = collectible ? getCollectibleGradeName(collectible) : null;
  const currentPrice = collectible ? getCollectibleCurrentPrice(collectible) : null;

  return (
    <CollectibleDescriptionUI
      className={className}
      title={title}
      subTitle={descriptionLine1}
      variationDescription={descriptionLine2}
      gradeName={gradeName}
      populationCount={populationCount}
      isLoading={isLoading}
      isManual={isManual}
      isRookie={isRookie}
      currentPrice={currentPrice?.currentPrice ? currentPrice?.currentPrice : customPrice?.currentPrice}
      currentPriceDate={currentPrice?.currentPrice ? currentPrice?.currentPriceDate : customPrice?.currentPriceDate}
      size={size}
      useLocalTimezone={isManual ?? false}
    />
  );
}

export interface SizeProps {
  size?: 'md' | 'lg';
}

export type CollectibleDescriptionUIProps = {
  className?: string;
  title: string | null;
  subTitle: string | null;
  isRookie?: boolean | null;
  isManual?: boolean | null;
} & VariationDescriptionProps &
  GradeDescriptionProps &
  CurrentPriceInfoProps &
  SizeProps;

export function CollectibleDescriptionUI({
  className,
  title,
  subTitle,
  variationDescription,
  gradeName,
  populationCount,
  currentPrice,
  currentPriceDate,
  isRookie = false,
  isManual = false,
  isLoading = false,
  size = 'md',
}: CollectibleDescriptionUIProps) {
  return (
    <div
      className={clsx(
        'twp text-lightFg dark:text-darkFg flex flex-col items-stretch justify-center max-w-[420px]',
        className
      )}
    >
      <h4
        className={clsx('flex items-center gap-2 flex-wrap line-clamp-2 text-start', {
          'animate-pulse': isLoading,
        })}
      >
        <span className="font-semibold text-base">{title ?? ''}</span>
        <RookieIndicator isRookie={isRookie} />
        <ManualIndicator isManual={isManual} />
      </h4>
      <p
        className={clsx('line-clamp-2 text-start', {
          'animate-pulse': isLoading,
          'text-sm': size === 'md',
          'text-base': size === 'lg',
        })}
      >
        {subTitle ?? ''}
      </p>
      <VariationDescription
        className={clsx({
          'mt-1': size === 'md',
          'mt-2': size === 'lg',
        })}
        variationDescription={variationDescription}
        isLoading={isLoading}
        size="md"
      />
      <GradeDescription
        className={clsx({
          'mt-1': size === 'md',
          'mt-2': size === 'lg',
        })}
        gradeName={gradeName}
        populationCount={populationCount}
        isLoading={isLoading}
        size="md"
      />
      <CurrentPriceInfo
        className={clsx({
          'mt-1': size === 'md',
          'mt-2': size === 'lg',
        })}
        currentPrice={currentPrice}
        currentPriceDate={currentPriceDate}
        isLoading={isLoading}
        size={size}
      />
    </div>
  );
}

export type VariationDescriptionProps = {
  className?: string;
  variationDescription: string | null;
  isLoading?: boolean;
} & SizeProps;

export function VariationDescription({
  variationDescription,
  className,
  isLoading,
  size = 'md',
}: VariationDescriptionProps) {
  if (!variationDescription && !isLoading) {
    return null;
  }
  return (
    <p
      className={clsx(
        'bg-lightBg-accentPrimary dark:bg-darkBg-accentPrimary border-lightBg-lightGrey dark:border-darkBg-lightGrey text-lightFg dark:text-darkFg',
        'border rounded-md px-2 py-1 text-xs line-clamp-2 text-start',
        {
          'animate-pulse': isLoading,
          'text-xs': size === 'md',
          'text-sm': size === 'lg',
        },
        className
      )}
    >
      {variationDescription ?? ''}
    </p>
  );
}

export type GradeDescriptionProps = {
  className?: string;
  gradeName: string | null;
  populationCount: number | null;
  isLoading?: boolean;
} & SizeProps;

export function GradeDescription({
  gradeName,
  className,
  populationCount,
  isLoading,
  size = 'md',
}: GradeDescriptionProps) {
  if (!gradeName && !isLoading) {
    return null;
  }
  return (
    <p
      className={clsx(
        'bg-brand border-brand border text-lightFg-inverse',
        'rounded-md px-2 py-1 text-xs line-clamp-1 text-start',
        {
          'animate-pulse': isLoading,
          'text-xs': size === 'md',
          'text-sm': size === 'lg',
        },
        className
      )}
    >
      {gradeName ?? ''}
      {!!populationCount &&
        ` (pop: ${formatStatValue({
          type: 'count',
          value: populationCount,
          formatOverrides: {
            notation: 'standard',
          },
        })})`}
    </p>
  );
}

export type CurrentPriceInfoProps = {
  className?: string;
  currentPrice: number | null | undefined;
  currentPriceDate: string | null | undefined;
  useLocalTimezone?: boolean;
  isLoading?: boolean;
} & SizeProps;

export function CurrentPriceInfo({
  className,
  currentPrice,
  currentPriceDate,
  isLoading,
  size = 'md',
  useLocalTimezone,
}: CurrentPriceInfoProps) {
  const { formatStatValue } = useFormatStatValue();
  if (!currentPrice && !isLoading) {
    return null;
  }
  return (
    <p
      className={clsx(
        'flex flex-row items-baseline gap-2 text-start',
        {
          'animate-pulse': isLoading,
        },
        className
      )}
    >
      <span
        className={clsx('font-semibold text-lg', {
          'text-md': size === 'md',
          'text-lg': size === 'lg',
        })}
      >
        {formatStatValue({
          type: 'price',
          value: currentPrice,
        }) ?? ''}
      </span>
      {!!currentPriceDate && (
        <span
          className={clsx('text-lightFg-gray dark:text-darkFg-gray', {
            'text-sm': size === 'md',
            'text-base': size === 'lg',
          })}
        >
          {useLocalTimezone ? formatDate(currentPriceDate) : formatDateInTimeZone(currentPriceDate)}
        </span>
      )}
    </p>
  );
}
