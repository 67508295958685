import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import { Button } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { SalesHistoryItemsList } from './SalesHistoryItemsList';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { EditForm, EditFormButtons, FormSeparator } from '@/sci-ui-components/forms/EditForm';
import useDebouncedValue from '@/sci-ui-components/hooks/useDebouncedValue';
import { useRawCompletedSalesSearch } from '@/features/sales-history/useRawCompletedSalesSearch';
import { useLastDayAverageSalePrice } from '@/features/sales-history/useLastDayAverageSalePrice';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from '@/sci-ui-components/forms/TextField/TextField';
import useCurrencyFormatter, { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';
import PriceField from '@/sci-ui-components/forms/PriceField/PriceField';
import { useGeneratedCollectibleQueryForCustomCollectible } from '@/features/collectibles/useGeneratedCollectibleQuery';
import { cropCollectibleImage } from '@/features/images/CropImageDialog';
import { CollectibleItem } from '@/features/collectibles/CollectibleItem';

const baseFormSchema = z.object({
  currentPriceInDisplayCurency: z.number().nullable().optional(),
  customQuery: z
    .string({
      required_error: 'Please enter box type',
    })
    .trim()
    .nullable()
    .default(null),
  imageUrl: z.string().nullable().optional(),
});

export type EditCustomCollectibleValueFormInitialValues = z.input<typeof baseFormSchema> & { itemId?: string | null };
export type EditCustomCollectibleValueFormPayload = z.output<typeof baseFormSchema>;
export type OnEditCustomCollectibleValueFormSubmitFn = (params: EditCustomCollectibleValueFormPayload) => void;

const fullFormSchema = baseFormSchema.extend({
  usingImageItemId: z.string().nullable().default(null),
  usingPriceItemId: z.string().nullable().default(null),
  prevCustomQuery: z.string().nullable().default(null), // NOTE: used for custom "undo" feature after using "generate" feature
});
type FormInput = z.input<typeof fullFormSchema>;
type FormValues = z.output<typeof fullFormSchema>;

const MANUAL_ENTRY_ITEM_ID = 'MANUAL'; // NOTE: used as usingPriceItemId when user types in value manually.

export interface EditCustomCollectibleValueCallbacks {
  onCustomQueryUpdated?: () => void;
  onCustomQueryGenerated?: () => void;
  onValueUsed?: () => void;
  onPhotoUsed?: () => void;
  onPhotoSelected?: () => void;
}

export function EditCustomCollectibleValueForm({
  onSubmit,
  onCancel,
  formId = 'EditCustomCollectibleValueForm',
  initialValues,
  customCollectible,
  isLoading,
  cancelText,
  submitText,
  onCustomQueryGenerated,
  onCustomQueryUpdated,
  onPhotoUsed,
  onValueUsed,
  onPhotoSelected,
}: {
  onSubmit: OnEditCustomCollectibleValueFormSubmitFn;
  onCancel: () => void;
  cancelText?: string;
  submitText?: string;
  formId?: string;
  initialValues: EditCustomCollectibleValueFormInitialValues | null;
  customCollectible: CustomCollectible;
  isLoading?: boolean;
} & EditCustomCollectibleValueCallbacks) {
  const { data: generatedQuery } = useGeneratedCollectibleQueryForCustomCollectible(customCollectible);
  const defaultValues = useMemo<FormInput>(() => {
    if (initialValues) {
      return {
        ...initialValues,
        usingPriceItemId:
          initialValues.itemId ?? (initialValues.currentPriceInDisplayCurency ? MANUAL_ENTRY_ITEM_ID : undefined),
        usingImageItemId: initialValues.itemId,
        customQuery: initialValues.customQuery || generatedQuery,
      };
    }
    return {
      customQuery: generatedQuery,
    };
  }, [initialValues, generatedQuery]);
  const form = useForm<FormInput, unknown, FormValues>({
    resolver: zodResolver(fullFormSchema),
    defaultValues,
    mode: 'onSubmit',
  });
  useOnValueChange(defaultValues, () => form.reset(defaultValues));

  const customQuery = form.watch('customQuery') ?? null;
  const prevCustomQuery = form.watch('prevCustomQuery') ?? null;
  const isCustomQueryGenerated = generatedQuery?.trim() === customQuery?.trim();
  const [debouncedSearchQuery] = useDebouncedValue(customQuery?.trim(), 500);
  const activeQuery = debouncedSearchQuery || generatedQuery || '';
  useOnValueChange(generatedQuery, () => {
    if (customQuery?.trim().length && !!generatedQuery) {
      form.setValue('customQuery', generatedQuery);
    }
  });

  const handleSubmit = form.handleSubmit((payload: FormValues) =>
    onSubmit({
      ...payload,
      customQuery: isCustomQueryGenerated ? null : payload.customQuery?.trim() || null,
    })
  );

  const { convertPriceFromUSD } = useCurrencyFormatter();
  const { items, isLoading: isLoadingSalesList } = useRawCompletedSalesSearch(
    {
      titleSearchQueryText: activeQuery,
      sort: [
        {
          sortBy: 'saleDate',
          sortDirection: 'desc',
        },
        {
          sortBy: 'score',
          sortDirection: 'desc',
        },
      ],
      limit: 10,
    },
    { enabled: !!activeQuery.length }
  );
  const lastDayAverageSalePrice = useLastDayAverageSalePrice({
    items,
  });
  const lastDayAverageSalePriceInDisplayCurrency = convertPriceFromUSD({ value: lastDayAverageSalePrice });
  const usingPriceItemId = form.watch('usingPriceItemId') ?? null;
  useEffect(() => {
    if (!usingPriceItemId && lastDayAverageSalePriceInDisplayCurrency) {
      form.setValue('currentPriceInDisplayCurency', lastDayAverageSalePriceInDisplayCurrency);
    }
  }, [usingPriceItemId, lastDayAverageSalePriceInDisplayCurrency, form]);

  const imageUrl = form.watch('imageUrl');
  const customCollectibleWithUpdates = useMemo(() => {
    if (!imageUrl || imageUrl === customCollectible.imageUrl) {
      return customCollectible;
    }
    return {
      ...customCollectible,
      imageUrl,
    };
  }, [imageUrl, customCollectible]);

  return (
    <>
      <FormSeparator noTopMargin />
      <CollectibleItem
        collectible={customCollectibleWithUpdates}
        collectibleType={customCollectibleWithUpdates.collectibleType}
        size="lg"
        onImageChange={(newImageUrl) => {
          form.setValue('usingImageItemId', MANUAL_ENTRY_ITEM_ID);
          form.setValue('imageUrl', newImageUrl);
          onPhotoSelected?.();
        }}
        className="mb-4"
      />
      <EditForm onSubmit={handleSubmit} id={formId} className="flex flex-col gap-6 w-[612px] md:w-[1024px] max-w-full">
        <div className="flex flex-col items-start gap-4">
          <Controller
            name="currentPriceInDisplayCurency"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                label={
                  <span className="flex items-center gap-1">
                    <span>New Price</span>
                    {!usingPriceItemId && (
                      <Tooltip title="The New Value is calculated based on the average of the last day's sales.">
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </Tooltip>
                    )}
                  </span>
                }
                fieldId="currentPriceInDisplayCurency"
                error={fieldState.error?.message}
                variant="neater"
              >
                <PriceField
                  {...field}
                  onChange={(value, name) => {
                    field.onChange({ target: { value, name } });
                    form.setValue('usingPriceItemId', MANUAL_ENTRY_ITEM_ID);
                  }}
                  id="currentPriceInDisplayCurency"
                  min={0}
                />
              </FieldLabelBox>
            )}
          />
          <Controller
            name="customQuery"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                className="w-full"
                label={
                  <span className="flex items-center gap-1">
                    <span>Custom Query</span>
                    <Tooltip title="By default we autopopulate this query based on what you saved for your card details. You may update this to enable quick price checks for all of your manual cards! Pro tip, use “-” to exclude terms you don’t want to see. Example: Tom Brady Bowman Chrome -refractor.">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </Tooltip>
                  </span>
                }
                fieldId="customQuery"
                error={fieldState.error?.message}
                variant="neater"
                adornmentRight={
                  <>
                    {!!generatedQuery && !isCustomQueryGenerated && (
                      <Button
                        variant="link"
                        size="small"
                        onClick={() => {
                          form.setValue('prevCustomQuery', customQuery);
                          form.setValue('customQuery', generatedQuery);
                          onCustomQueryGenerated?.();
                        }}
                      >
                        Generate Query
                      </Button>
                    )}
                    {isCustomQueryGenerated && !!prevCustomQuery?.trim().length && (
                      <Button
                        variant="link"
                        size="small"
                        onClick={() => {
                          form.setValue('customQuery', prevCustomQuery);
                          form.setValue('prevCustomQuery', null);
                        }}
                      >
                        Undo
                      </Button>
                    )}
                  </>
                }
              >
                <TextField
                  {...field}
                  onChange={(value) => {
                    onCustomQueryUpdated?.();
                    field.onChange({ target: { value, name: field.name } });
                  }}
                  id="customQuery"
                  isMultiLine
                  rows={2}
                />
              </FieldLabelBox>
            )}
          />
        </div>
        <SalesHistoryItemsList
          className="max-h-80 bg-lightBg dark:bg-darkBg"
          items={items}
          isLoading={isLoadingSalesList}
          collectibleType={customCollectible.collectibleType}
          usingImageItemId={form.watch('usingImageItemId') ?? null}
          usingPriceItemId={usingPriceItemId}
          onUseImage={async ({ imageUrl, itemId }) => {
            const croppedImage = await cropCollectibleImage({
              imageUrl,
              collectibleType: customCollectible.collectibleType,
            });
            if (croppedImage) {
              form.setValue('usingImageItemId', itemId);
              form.setValue('imageUrl', croppedImage);
            }
            onPhotoUsed?.();
          }}
          onUseValue={({ value, itemId }) => {
            form.setValue('usingPriceItemId', itemId);
            form.setValue('currentPriceInDisplayCurency', convertPriceFromUSD({ value }));
            onValueUsed?.();
          }}
        />
      </EditForm>
      <EditFormButtons
        className="col-span-3 md:col-span-1 md:col-start-3 md:row-start-4"
        onCancel={onCancel}
        isLoading={isLoading}
        formId={formId}
        cancelText={cancelText}
        submitText={submitText}
      />
    </>
  );
}

export function getEditCustomCollectibleValueFormInitialValues({
  customCollectible,
  convertPriceFromUSD,
}: {
  convertPriceFromUSD: ConvertPriceFn;
  customCollectible: CustomCollectible | null;
}): EditCustomCollectibleValueFormInitialValues {
  return {
    currentPriceInDisplayCurency: convertPriceFromUSD({ value: customCollectible?.price }),
    customQuery: customCollectible?.customQuery,
    imageUrl: customCollectible?.imageUrl,
  };
}
