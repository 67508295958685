import { SellerInfo, marketplaceNames, Marketplace, marketplaces } from '../../../../sci-ui-components/types/sales';

export default function getSellerInfo(source: string, sellerName: string | null): SellerInfo {
  if (source === 'myslabs_jobs') {
    return {
      logoUrl: '/icons/platforms/myslabs.png',
      marketplace: 'myslabs',
      sellerName,
      isKnownSeller: true,
    };
  }
  if (sellerName === 'Goldin Auctions') {
    return {
      logoUrl: '/icons/platforms/goldin_auctions.png',
      marketplace: 'ebay_auctions',
      sellerName,
      isKnownSeller: true,
    };
  }
  if (sellerName === 'PWCC') {
    return {
      logoUrl: '/icons/platforms/pwcc.png',
      marketplace: 'pwcc',
      sellerName,
      isKnownSeller: true,
    };
  }
  if (sellerName === 'Memory Lane') {
    return {
      logoUrl: '/icons/platforms/ml.png',
      marketplace: 'ebay_auctions',
      sellerName,
      isKnownSeller: true,
    };
  }
  if (sellerName === 'Robert Edward Auctions') {
    return {
      logoUrl: '/icons/platforms/rea.png',
      marketplace: 'ebay_auctions',
      sellerName,
      isKnownSeller: true,
    };
  }
  if (sellerName === 'SCP Auctions') {
    return {
      logoUrl: '/icons/platforms/scp.png',
      marketplace: 'ebay_auctions',
      sellerName,
      isKnownSeller: true,
    };
  }
  if (sellerName === 'Heritage Auctions') {
    return {
      logoUrl: '/icons/platforms/heritage.png',
      marketplace: 'ebay_auctions',
      sellerName: 'Heritage Auctions',
      isKnownSeller: true,
    };
  }
  if (sellerName === 'COMC') {
    return {
      logoUrl: '/icons/platforms/comc.png',
      marketplace: 'ebay_auctions',
      sellerName,
      isKnownSeller: true,
    };
  }
  if (isSourceAMarketplace(source)) {
    return {
      logoUrl: `/icons/platforms/${source}.png`,
      marketplace: source,
      sellerName: marketplaceNames[source],
      isKnownSeller: true,
    };
  }
  return {
    logoUrl: '/icons/platforms/ebay.png',
    marketplace: 'ebay_auctions',
    sellerName,
    isKnownSeller: false,
  };
}

function isSourceAMarketplace(source: string): source is Marketplace {
  return marketplaces.includes(source as Marketplace);
}
