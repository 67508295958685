import { createContext, ReactNode, useContext } from 'react';

type TylerModeContextValue = [boolean, (v: boolean) => void];
const TylerModeContext = createContext<TylerModeContextValue | undefined>(undefined);

export function TylerModeContextProvider({ children, value }: { children: ReactNode; value: TylerModeContextValue }) {
  return <TylerModeContext.Provider value={value}>{children}</TylerModeContext.Provider>;
}

export function useTylerMode() {
  return useContext(TylerModeContext);
}

export function useIsTylerMode() {
  return useTylerMode()?.[0] ?? false;
}
