import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiGrade, ApiAlternateCardGrade } from '../types';
import cardGradeFromApi from '../collectibles/transformers/cardGradeFromApi';
import alternateCardGradeFromApi from './transformers/alternateCardGradeFromApi';

export async function getCardGrades(signal?: AbortSignal) {
  const apiItems = await authenticatedFetchFromSciApi<ApiGrade[]>('/grades', {
    method: 'GET',
    signal,
  });
  return apiItems?.map(cardGradeFromApi)?.sort((g1, g2) => g1.order - g2.order);
}

export interface GetAlternateCardGradesParams {
  collectibleId: number;
}

export async function getAlternateCardGrades({ collectibleId }: GetAlternateCardGradesParams, signal?: AbortSignal) {
  const apiItems = await authenticatedFetchFromSciApi<ApiAlternateCardGrade[]>(`/grades/available/${collectibleId}`, {
    method: 'GET',
    signal,
  });
  return apiItems.map(alternateCardGradeFromApi);
}
