import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';
import { aspectRatiosMap } from '@/sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import { InitialCropShift } from '@/sci-ui-components/forms/ImageCropper/ImageCropper';

interface CropImageDialogState {
  /**
   * Can be any URL: data url, internal or external URL
   */
  imageUrl: string | null;
  aspectRatio?: number | null;
  modalWidth?: number | null;
  autoCropArea?: number | null;
  initialCropShift?: InitialCropShift | null;
  onDone: null | ((newImageUrl: string | null) => void);
  close: (newImageUrl: string | null | void) => void;
}

export const useCropImageDialogStore = create<CropImageDialogState>()((set) => ({
  imageUrl: null,
  onDone: null,
  close: (newImageUrl) => {
    set(({ onDone }) => {
      onDone?.(newImageUrl ?? null);
      return {
        imageUrl: null,
        onDone: null,
        aspectRatio: null,
        modalWidth: null,
        autoCropArea: null,
        initialCropShift: null,
      };
    });
  },
}));

export type CropImageProps = Pick<CropImageDialogState, 'imageUrl' | 'aspectRatio' | 'autoCropArea' | 'modalWidth'>;
export async function cropImage(params: CropImageProps): Promise<string | null> {
  const resultPromise = Promise.withResolvers<string | null>();
  useCropImageDialogStore.setState({
    ...params,
    onDone: resultPromise.resolve,
  });
  return resultPromise.promise;
}

export type CropCollectibleImageProps = Pick<CropImageDialogState, 'imageUrl'> & {
  collectibleType: CollectibleType;
};
export async function cropCollectibleImage({
  collectibleType,
  ...rest
}: CropCollectibleImageProps): Promise<string | null> {
  const collectibleAspectRatio = aspectRatiosMap[collectibleType];
  const aspectRatio = collectibleAspectRatio[0] / collectibleAspectRatio[1];
  const resultPromise = Promise.withResolvers<string | null>();
  useCropImageDialogStore.setState({
    ...rest,
    aspectRatio,
    modalWidth: 720,
    onDone: resultPromise.resolve,
    initialCropShift:
      collectibleType === 'sports-card'
        ? {
            topPercentage: 0.14, // NOTE: card is usually slightly lower than center duee to grading slub
          }
        : undefined,
  });
  return resultPromise.promise;
}
