import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';
import type {
  EditCustomCollectionItemFormPayload,
  EditCustomCollectionItemFormInitialValues,
} from '../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
import type { EditCollectionItemPurchaseDetailsInitialValues } from '../EditCollectionItemPurchaseDetailsDialog/EditCollectionItemPurchaseDetailsForm';
import type {
  EditCustomCollectibleValueFormInitialValues,
  EditCustomCollectibleValueFormPayload,
} from '../EditCustomCollectibleValueDialog/EditCustomCollectibleValueForm';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';
import { trackEvent } from '@/features/analytics/trackEvent';

interface AddCollectionItemDialogState {
  isOpen: boolean;
  currentStep: AddCollectionItemDialogStep;
  initialStep: 'search' | 'purchase_details' | 'custom_collectible';
  collectibleType: CollectibleType;
  initialSearchText: string | null;
  featuredCollectibleId: number | null;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
  customCollectibleInfo: EditCustomCollectionItemFormPayload | null;
  initialCustomCollectibleInfo: EditCustomCollectionItemFormInitialValues | null;
  customCollectibleValueInfo: EditCustomCollectibleValueFormPayload | null;
  initialCustomCollectibleValueInfo: EditCustomCollectibleValueFormInitialValues | null;
  initialPurchaseDetails: EditCollectionItemPurchaseDetailsInitialValues | null;
  onDone: ((isAdded: boolean) => void) | null;
  close: (isAdded: boolean | void) => void;
  goBackTo: (step: Exclude<AddCollectionItemDialogStep, 'purchase_details'>) => void;
  saveSearchResult: (payload: {
    initialSearchText: string;
    featuredCollectibleId: number | null;
    initialCustomCollectibleInfo?: EditCustomCollectionItemFormInitialValues | null;
    initialCustomCollectibleValueInfo?: EditCustomCollectibleValueFormInitialValues | null;
    initialPurchaseDetails?: EditCollectionItemPurchaseDetailsInitialValues | null;
    salesHistoryItem?: RawCompletedSaleSearchItem | null;
  }) => void;
  saveCustomCollectibleInfo: (payload: EditCustomCollectionItemFormPayload) => void;
  saveCustomCollectibleValueInfo: (payload: EditCustomCollectibleValueFormPayload) => void;
}

export type AddCollectionItemDialogStep = 'search' | 'purchase_details' | 'custom_collectible' | 'check_value';

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useAddCollectionItemDialogStore = create<AddCollectionItemDialogState>()((set) => ({
  isOpen: false,
  currentStep: 'search',
  initialStep: 'search',
  collectibleType: defaultCollectibleType,
  initialSearchText: null,
  featuredCollectibleId: null,
  initialCustomCollectibleInfo: null,
  customCollectibleInfo: null,
  initialCustomCollectibleValueInfo: null,
  customCollectibleValueInfo: null,
  initialPurchaseDetails: null,
  onDone: null,
  goBackTo: (step: Exclude<AddCollectionItemDialogStep, 'purchase_details'>) =>
    set(({ collectibleType }) => {
      trackEvent({
        eventName: 'COLLECTION_ADD_GO_BACK',
        collectibleType,
      });
      return {
        currentStep: step,
        ...(step === 'search'
          ? {
              featuredCollectibleId: null,
              customCollectibleInfo: null,
            }
          : {}),
      };
    }),
  close: (isAdded) =>
    set(({ onDone, collectibleType }) => {
      trackEvent({
        eventName: isAdded ? 'COLLECTION_ADD_COMPLETED' : 'COLLECTION_ADD_CANCELLED',
        collectibleType,
      });
      onDone?.(isAdded ?? false);
      return {
        isOpen: false,
        currentStep: 'search',
        initialStep: 'search',
        collectibleType: defaultCollectibleType,
        initialSearchText: null,
        featuredCollectibleId: null,
        initialCustomCollectibleInfo: null,
        customCollectibleInfo: null,
        initialPurchaseDetails: null,
        initialCustomCollectibleValueInfo: null,
        customCollectibleValueInfo: null,
        onDone: null,
        salesHistoryItem: null,
      } satisfies Partial<AddCollectionItemDialogState>;
    }),
  saveSearchResult: ({
    featuredCollectibleId,
    initialCustomCollectibleInfo,
    initialCustomCollectibleValueInfo,
    initialPurchaseDetails,
    initialSearchText,
    salesHistoryItem,
  }) => {
    if (featuredCollectibleId) {
      set({
        initialSearchText,
        currentStep: 'purchase_details',
        featuredCollectibleId,
        initialPurchaseDetails,
        salesHistoryItem: null,
      });
    } else {
      set({
        initialSearchText,
        currentStep: 'custom_collectible',
        initialCustomCollectibleInfo,
        initialCustomCollectibleValueInfo,
        initialPurchaseDetails,
        salesHistoryItem,
      });
    }
  },
  saveCustomCollectibleInfo: (customCollectibleInfo) =>
    set({
      customCollectibleInfo,
      currentStep: 'check_value',
    }),
  saveCustomCollectibleValueInfo: (customCollectibleValueInfo) =>
    set({
      customCollectibleValueInfo,
      currentStep: 'purchase_details',
    }),
}));

export interface AddCollectionItemProps {
  collectibleType: CollectibleType;
  initialPurchaseDetails?: EditCollectionItemPurchaseDetailsInitialValues | null;
  initialCustomCollectibleInfo?: EditCustomCollectionItemFormInitialValues | null;
  initialCustomCollectibleValueInfo?: EditCustomCollectibleValueFormInitialValues | null;
  featuredCollectibleId: null | number;
  initialSearchText?: string | null;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
}

export async function addCollectionItem({
  collectibleType,
  initialCustomCollectibleInfo,
  featuredCollectibleId,
  initialPurchaseDetails,
  initialSearchText,
  initialCustomCollectibleValueInfo,
  salesHistoryItem,
}: AddCollectionItemProps) {
  const { promise, resolve } = Promise.withResolvers<boolean>();
  useAddCollectionItemDialogStore.setState({
    onDone: resolve,
  });
  if (featuredCollectibleId) {
    useAddCollectionItemDialogStore.setState({
      isOpen: true,
      currentStep: 'purchase_details',
      initialStep: 'purchase_details',
      collectibleType,
      featuredCollectibleId,
      customCollectibleInfo: null,
      initialPurchaseDetails,
    });
  } else if (initialCustomCollectibleInfo) {
    useAddCollectionItemDialogStore.setState({
      isOpen: true,
      currentStep: 'custom_collectible',
      initialStep: 'custom_collectible',
      collectibleType,
      initialCustomCollectibleInfo,
      initialCustomCollectibleValueInfo,
      initialPurchaseDetails,
      salesHistoryItem,
    });
  } else {
    useAddCollectionItemDialogStore.setState({
      isOpen: true,
      currentStep: 'search',
      initialStep: 'search',
      collectibleType,
      initialSearchText,
      initialPurchaseDetails,
    });
  }
  return promise;
}
