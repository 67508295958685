import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { GetPlayersParams, getPlayers } from '../../services/sciApi/players/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { Player } from '../../sci-ui-components/types/player';
import useFilteredData from 'hooks/useFilteredData';

export type UsePlayersParams = Omit<GetPlayersParams, 'offset'> & {
  sportIds?: number[] | null;
  searchText?: string | null;
};

export const keyPrefix = 'players';
type UsePlayersKey = [typeof keyPrefix, Omit<UsePlayersParams, 'searchText' | 'sportIds'>];

export default function usePlayers(
  { ids, limit, searchText, sportIds }: UsePlayersParams = {},
  options: UseInfiniteQueryOptions<Player[], ApiError, Player[], Player[], UsePlayersKey> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useInfiniteQuery(
    [
      keyPrefix,
      {
        ids,
        limit,
      },
    ],
    async ({ pageParam: offset = 0, signal }) =>
      getPlayers(
        {
          ids,
          limit,
          offset,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      ...options,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.length || !limit) {
          return null;
        }
        const totalItems = allPages.reduce((acc, items) => acc + items.length, 0);
        return totalItems;
      },
    }
  );

  // TODO: this should be done in API instead
  const filterBySportIds = useMemo(() => {
    const sportIdsSet = new Set(sportIds ?? []);
    return ({ sportId }: Player) => !sportIdsSet.size || sportIdsSet.has(sportId);
  }, [sportIds]);
  const data = useMemo(
    () => queryResult.data?.pages?.reduce<Player[]>((acc, page) => acc.concat(page), []),
    [queryResult.data]
  );
  const filteredData = useFilteredData(data, 'name', searchText, [filterBySportIds]);

  return {
    ...queryResult,
    data: filteredData,
  };
}
