import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { mmApiClient, MmApiInput, MmApiOutput } from '@/services/mmApiX';

export function useGetImageDataUrl() {
  return useMutation({
    mutationFn: mmApiClient.private.images.getBase64ImageDataUrl.query,
  });
}

export const useImageDataUrlKeyPrefix = 'collection-categories';
type QueryKey = [typeof useImageDataUrlKeyPrefix, UseListCollectionItemsInput];
export type UseListCollectionItemsInput = Exclude<MmApiInput['private']['images']['getBase64ImageDataUrl'], void>;
export type UseListCollectionItemsOutput = MmApiOutput['private']['images']['getBase64ImageDataUrl'];
export function useImageDataUrl(
  input: UseListCollectionItemsInput,
  options: UseQueryOptions<UseListCollectionItemsOutput, Error, UseListCollectionItemsOutput, QueryKey> = {}
) {
  return useQuery(
    [useImageDataUrlKeyPrefix, input],
    () => mmApiClient.private.images.getBase64ImageDataUrl.query(input),
    options
  );
}
