import { useCallback, useMemo } from 'react';
import {
  useAuthenticatedMMAPIInfiniteQuery,
  MmApiInfiniteQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
} from '../../services/mmApiX/index';
import { getOSTextSearchParams } from '../sales/helpers';

export type UseRawCompletedSalesSearchInput = MmApiInput['private']['rawSales']['completed']['search'];
export type UseRawCompletedSalesSearchOutput = MmApiOutput['private']['rawSales']['completed']['search'];
export type RawCompletedSaleSearchItem = Exclude<UseRawCompletedSalesSearchOutput, void | null>['items'][number];
export type RawCompletedSaleSearchItemSpellCheckSuggestions = Exclude<
  UseRawCompletedSalesSearchOutput,
  void | null
>['spellCheckSuggestions'];

export const useInfiniteRawCompletedSalesSearchKeyPrefix = 'private.rawSales.completed.search';
export const useRawCompletedSalesSearchKeyPrefix = 'private.rawSales.completed.search';
type QueryKey = [typeof useRawCompletedSalesSearchKeyPrefix, UseRawCompletedSalesSearchInput];

export function useInfiniteRawCompletedSalesSearch(
  input: UseRawCompletedSalesSearchInput,
  options: MmApiInfiniteQueryOptions<UseRawCompletedSalesSearchOutput, QueryKey> = {}
) {
  const queryKey: QueryKey = [useInfiniteRawCompletedSalesSearchKeyPrefix, input];
  const formattedInput = {
    ...input,
    ...{ titleSearchQueryText: getOSTextSearchParams(input.titleSearchQueryText || '') },
  };

  const result = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    async ({ pageParam: totalFetched }) =>
      mmApiClient.private.rawSales.completed.search.query({
        ...formattedInput,
        offset: totalFetched,
        disableSpellCheck: !!totalFetched || formattedInput.disableSpellCheck,
      }),
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...(options ?? {}),
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return null;
        }
        return lastPage.nextOffset;
      },
    }
  );

  const [allItems, totalCount, extraItems, verifiedSpellCheckedAlternatives] = useMemo(() => {
    return (
      result.data?.pages?.reduce<
        [RawCompletedSaleSearchItem[], number, RawCompletedSaleSearchItem[], string[], Set<string>]
      >(
        (acc, page) => {
          const previousPageVerifiedSpellchecks = acc[3] || [];
          page.items.forEach((item) => {
            if (!acc[4].has(item._id)) {
              acc[0].push(item);
            }
            acc[4].add(item._id);
          });
          acc[1] = page.totalCount;
          acc[2] = page.extraItems || [];
          acc[3] = previousPageVerifiedSpellchecks.length
            ? previousPageVerifiedSpellchecks
            : page.verifiedSuggestions || [];
          return acc;
        },
        [[], 0, [], [], new Set()]
      ) ?? [[], 0, [], []]
    );
  }, [result.data]);

  const { hasNextPage, fetchNextPage } = result;
  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return {
    ...result,
    allItems,
    totalCount,
    verifiedSpellCheckedAlternatives,
    extraItems,
    fetchNextPageIfAvailable,
  };
}

export function useRawCompletedSalesSearch(
  input: UseRawCompletedSalesSearchInput,
  options: MmApiQueryOptions<UseRawCompletedSalesSearchOutput, QueryKey> = {}
) {
  const queryKey: QueryKey = [useRawCompletedSalesSearchKeyPrefix, input];

  const formattedInput = {
    ...input,
    ...{ titleSearchQueryText: getOSTextSearchParams(input.titleSearchQueryText || '') },
  };

  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    () =>
      mmApiClient.private.rawSales.completed.search.query({
        ...formattedInput,
        offset: input.offset,
        limit: input.limit,
      }),
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...options,
    }
  );

  const totalCount = result?.data?.totalCount || 0;
  const items = result?.data?.items;
  const verifiedSpellCheckedAlternatives = result?.data?.verifiedSuggestions || [];

  return {
    items: items || [],
    totalCount,
    extraItems: result?.data?.extraItems || [],
    verifiedSpellCheckedAlternatives,
    isLoading: result?.isLoading,
  };
}
