import { useState, useEffect } from 'react';

const defaultTimeout = 500;

export default function useDebouncedValue<T = unknown>(
  value: T,
  timeout: number = defaultTimeout,
  { onChange }: { onChange?: () => void } = {}
): [T, boolean] {
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (!timeout) {
      setDebouncedValue(value);
      onChange?.();
      return () => {
        // nothing to clear
      };
    }
    const timeoutHandle = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
      onChange?.();
    }, timeout);
    setIsDebouncing(true);
    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [value, timeout, onChange]);

  return [debouncedValue, isDebouncing];
}
