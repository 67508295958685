import { useMemo, useCallback } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import useListExchangeRates from '../features/exchangeRates/useListExchangeRates';
import useUserSettings from '../features/settings/useUserSettings';
export type MaybeNumber = number | null | undefined;

export type ConvertPriceFn<T extends MaybeNumber = MaybeNumber> = ({ value }: { value: T }) => T;

const nonDecimalCurrencyCodes = new Set(['JPY', 'KRW', 'IDR', 'VND', 'CLP', 'PYG', 'MGA', 'MRO']);

export default function useCurrencyFormatter() {
  const { isLoading: isLoadingUserSettings, selectedCurrencyCode: selectedCurrencyCodeBase } = useUserSettings();
  const { data: exchangeRatesData, isLoading: isLoadingExchangeRates } = useListExchangeRates({});
  const isLoading = isLoadingUserSettings || isLoadingExchangeRates;

  const exchangeRate = useMemo(
    () => exchangeRatesData?.data?.find((d) => d.currency_code === selectedCurrencyCodeBase)?.rate,
    [exchangeRatesData, selectedCurrencyCodeBase]
  );

  const selectedCurrencyCode = isLoading || !exchangeRate ? 'USD' : selectedCurrencyCodeBase;

  const selectedCurrencySymbol = getSymbolFromCurrency(selectedCurrencyCode) || '';

  const selectedCurrencyRate = selectedCurrencyCode === 'USD' ? 1 : exchangeRate || 1;

  const convertPriceFromUSD = useCallback(
    <T extends MaybeNumber>({ value }: { value: T }): T => {
      if (!value) return value;
      const convertedPrice = selectedCurrencyRate * value;
      if (nonDecimalCurrencyCodes.has(selectedCurrencyCode)) {
        return Math.round(convertedPrice) as T;
      }
      return convertedPrice as T;
    },
    [selectedCurrencyRate, selectedCurrencyCode]
  );

  const convertPriceToUSD = useCallback(
    <T extends MaybeNumber>({ value }: { value: T }): T => {
      if (!value) return value;
      return (value / selectedCurrencyRate) as T;
    },
    [selectedCurrencyRate]
  );

  return {
    isLoading,
    selectedCurrencyCode,
    convertPriceFromUSD,
    convertPriceToUSD,
    selectedCurrencySymbol,
  };
}
