import { Sport } from '../../../../sci-ui-components/types/sport';
import { ApiSport } from '../../types';

export default function sportFromApi(apiSport: ApiSport): Sport {
  return {
    id: apiSport.id,
    name: apiSport.name,
    query: apiSport.query,
  };
}
