import qs from 'qs';
import { FavoritePlayer } from '../../../sci-ui-components/types/favoritePlayer';
import { SortDirection } from '../../../sci-ui-components/types/sort';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiFavoriteCategory, ApiFavoritePlayer } from '../types';
import favoritePlayerFromApi from './transformers/favoritePlayerFromApi';
import { playerApiType } from './utils';

// NOTE: only applicable to sports-cards collectible type

export interface GetFavoritePlayersByCategoryParams {
  categoryId: number;
  searchText?: string | null;
  offset?: number;
  limit?: number;
  sortBy?: GetFavoritePlayersByCategorySortByOption;
  sortDirection?: SortDirection;
}

export interface GetFavoritePlayersByCategoryResponse {
  items: FavoritePlayer[];
  totalCount: number;
}

export type GetFavoritePlayersByCategorySortByOption = 'title' | 'last7' | 'last30' | 'last90' | 'last_avg_price';

export async function getFavoritePlayersByCategory(
  {
    categoryId,
    searchText,
    offset = 0,
    limit = 10,
    sortBy = 'title',
    sortDirection = 'asc',
  }: GetFavoritePlayersByCategoryParams,
  signal: AbortSignal | undefined
): Promise<GetFavoritePlayersByCategoryResponse> {
  const { data: apiItems, totalCount } = await authenticatedFetchFromSciApi<{
    data: ApiFavoritePlayer[];
    totalCount: number;
  }>(
    `/favorite-categories/${categoryId}/favorites${qs.stringify(
      {
        type: playerApiType,
        title: searchText,
        offset,
        limit,
        sortBy,
        orderBy: sortDirection,
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  return {
    items: apiItems?.map((item) => favoritePlayerFromApi(item, categoryId)) ?? [],
    totalCount,
  };
}

export interface FavoritePlayerIds {
  playerId: number;
  favoriteId: number;
}

export async function getAllFavoritePlayerIds(signal: AbortSignal | undefined) {
  const shallowFavorites = await authenticatedFetchFromSciApi<
    {
      id: number;
      foreign_id: number;
      type: string;
    }[]
  >(
    `/favorite/${playerApiType}${qs.stringify(
      {
        full: false,
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  return shallowFavorites.map<FavoritePlayerIds>(({ foreign_id, id }) => ({
    playerId: foreign_id,
    favoriteId: id,
  }));
}

export interface AddFavoritePlayerParams {
  categoryId: number;
  playerId: number;
}

export async function addFavoritePlayer({ categoryId, playerId }: AddFavoritePlayerParams) {
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite', {
    method: 'POST',
    body: JSON.stringify({
      category_id: categoryId,
      foreign_id: playerId,
      type: playerApiType,
    }),
  });
}

export interface MoveFavoritePlayerToCategoryParams {
  categoryId: number;
  favoriteId: number;
}

export async function moveFavoritePlayerToCategory({ categoryId, favoriteId }: MoveFavoritePlayerToCategoryParams) {
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>(`/favorites/${favoriteId}`, {
    method: 'PUT',
    body: JSON.stringify({
      category_id: categoryId,
    }),
  });
}

export interface RemoveFavoritePlayerParams {
  favoriteIds: number[];
}

export async function removeFavoritePlayer({ favoriteIds }: RemoveFavoritePlayerParams) {
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite', {
    method: 'DELETE',
    body: JSON.stringify({
      favorites: favoriteIds,
    }),
  });
}
