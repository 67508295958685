import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { AddOrEditFavoriteDialogInfoProps } from './types';

const { invokeDialog: addOrEditFavorite, registerDialog } = createDialogSingleton<
  AddOrEditFavoriteDialogInfoProps,
  void
>();
const addFavorite = (props: Omit<AddOrEditFavoriteDialogInfoProps, 'favoriteId'>) => addOrEditFavorite(props);
const editFavorite = addOrEditFavorite;

export { addFavorite, editFavorite, registerDialog };
export type AddOrEditFavoriteDialogProviderProps = DialogProviderProps<AddOrEditFavoriteDialogInfoProps, void>;
