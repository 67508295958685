import { useCallback, useMemo } from 'react';
import useUser from '../user/useUser';
import { getLimitForMembershipTierPermissionCategory } from '../user/utils';
import { mmApiClient, MmApiInput, MmApiOutput, useAuthenticatedMMAPIQuery, MmApiQueryOptions } from '@/services/mmApiX';

export type UseFavoriteIdsInput = Exclude<MmApiInput['private']['favorites']['getAllIds'], void>;
export type UseFavoriteIdsOutput = MmApiOutput['private']['favorites']['getAllIds'];
export const useFavoriteIdsKeyPrefix = 'private.favorites.collectibles.getAllIds' as const;
export type UseFavoriteIdsKey = [typeof useFavoriteIdsKeyPrefix, UseFavoriteIdsInput];
export const getUseFavoriteIdsQueryKey = (input: UseFavoriteIdsInput): UseFavoriteIdsKey => [
  useFavoriteIdsKeyPrefix,
  input,
];

export function useFavoriteIds(
  input: UseFavoriteIdsInput,
  options?: MmApiQueryOptions<UseFavoriteIdsOutput, UseFavoriteIdsKey>
) {
  const { isLoading, isError, data, refetch } = useAuthenticatedMMAPIQuery(
    getUseFavoriteIdsQueryKey(input),
    async () => mmApiClient.private.favorites.getAllIds.query(input),
    options
  );
  const { data: user } = useUser();
  const entitiyIdsMap = useMemo(() => new Map(data?.map((v) => [v.entityId, v.id])), [data]);
  const favoritesCount = entitiyIdsMap.size;
  const checkIfFavorite = useCallback((id: number) => entitiyIdsMap.has(id), [entitiyIdsMap]);
  const getFavoriteIdByEntityId = useCallback((id: number) => entitiyIdsMap.get(id), [entitiyIdsMap]);
  const getEntityIdByFavoriteId = useCallback((id: number) => data?.find((item) => item.id === id)?.entityId, [data]);
  const isUserAtFavoritesLimit = useMemo(() => {
    const limit = getLimitForMembershipTierPermissionCategory(user, 'favorites');
    return limit !== Infinity && favoritesCount >= limit;
  }, [favoritesCount, user]);

  return {
    isLoading,
    isError,
    refetch,
    favoritesCount,
    checkIfFavorite,
    getFavoriteIdByEntityId,
    getEntityIdByFavoriteId,
    isUserAtFavoritesLimit,
  };
}
