import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { getSealedWaxBoxTypes } from '../../services/sciApi/sealedWaxBoxTypes/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { SealedWaxBoxType } from '../../sci-ui-components/types/sealedWaxBoxType';
import useFilteredData from 'hooks/useFilteredData';
export type { SealedWaxBoxType } from '../../sci-ui-components/types/sealedWaxBoxType';

export const anySealedWaxBoxTypeOptions: SealedWaxBoxType[] = [
  {
    id: 0,
    name: 'Any Boxes',
    query: 'box -case',
  },
  {
    id: 1,
    name: 'Any Cases',
    query: 'case -hit',
  },
  {
    id: 2,
    name: 'Any Packs',
    query: 'pack -(box,boxes,case,cases)',
  },
];

export type UseCardGradesParams = {
  searchText?: string | null;
  allowAny?: boolean;
};

export const keyPrefix = 'sealed-wax-box-types';
type Key = [typeof keyPrefix];

export default function useSealedWaxBoxTypes(
  { searchText, allowAny }: UseCardGradesParams,
  options: UseQueryOptions<SealedWaxBoxType[], ApiError, SealedWaxBoxType[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery([keyPrefix], ({ signal }) => getSealedWaxBoxTypes(signal), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
    enabled: !!isLoggedIn && (options?.enabled ?? true),
  });
  const dataWithAny = useMemo(
    () => (allowAny ? [...anySealedWaxBoxTypeOptions, ...(queryResult.data ?? [])] : queryResult.data),
    [allowAny, queryResult.data]
  );

  // TODO: this should be done in API instead
  const filteredData = useFilteredData(dataWithAny, 'name', searchText, []);

  return {
    ...queryResult,
    data: filteredData,
  };
}
