import React from 'react';
import clsx from 'clsx';
import classes from './RookieIndicator.module.scss';

export interface RookieIndicatorProps {
  /**
   * Is card from a set year during rookie years
   */
  isRookie?: boolean | null;
  className?: string;
}

/**
 * A visual indicator of a rookie sport card
 */
export default function RookieIndicator({ isRookie = true, className }: RookieIndicatorProps) {
  if (!isRookie) {
    return null;
  }
  return (
    <span className={clsx(classes.root, className)} title="Rookie">
      RC
    </span>
  );
}
