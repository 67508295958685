export default function createDialogSingleton<TDialogProps extends object = any, DialogResult extends any = void>() {
  type DialogProviderProps = TDialogProps & {
    resolve: (result: DialogResult) => void;
  };
  type StartDialogFn = (props: DialogProviderProps) => void;
  const dialogs: Map<string, StartDialogFn> = new Map();

  async function invokeDialog({
    dialogId,
    ...props
  }: TDialogProps & {
    dialogId?: string;
  }): Promise<DialogResult> {
    return new Promise((resolve, reject) => {
      const startDialog = dialogId ? dialogs.get(dialogId) : Array.from(dialogs.values())[0];
      if (!startDialog) {
        return reject(`Dialog provider is not registered${dialogId ? `: ${dialogId}` : ''}`);
      }
      startDialog({
        resolve,
        ...(props as TDialogProps),
      });
    });
  }

  function registerDialog(id: string, cb: StartDialogFn): () => void {
    dialogs.set(id, cb);
    return () => dialogs.delete(id);
  }

  return {
    registerDialog,
    invokeDialog,
  };
}

export type DialogProviderProps<TDialogProps extends object = any, DialogResult extends any = void> = TDialogProps & {
  resolve: (result: DialogResult) => void;
};
