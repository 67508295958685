export interface ConstructEbayQueryParams {
  singleFieldQuery?: string;
  setYearQueries?: string[];
  playerNameQueries?: string[];
  setQueries?: string[];
  sportQueries?: string[];
  setVariationQueries?: string[];
  sportsCardGradeQueries?: string[];
  sportsCardNumber?: string;
  sealedWaxBoxTypeQuery?: string;
  termsToInclude?: string[];
  termsToExclude?: string[];
  excludeLots?: boolean;
  excludeSealedWaxTerms?: boolean;
  excludeBreaks?: boolean;
  removeSportsFromSetNames?: boolean;
}

// TODO: move this to api
// NOTE: this function is a mess, but it is not worth fixing it as it should be removed entirely and all of this business logic should live in API
export default function constructEbayQuery({
  singleFieldQuery,
  setYearQueries,
  playerNameQueries,
  setQueries,
  sportQueries,
  setVariationQueries,
  sealedWaxBoxTypeQuery,
  sportsCardGradeQueries,
  sportsCardNumber,
  termsToInclude,
  termsToExclude,
  excludeLots,
  excludeSealedWaxTerms,
  excludeBreaks,
  removeSportsFromSetNames,
}: ConstructEbayQueryParams): string {
  const queryParts = [
    singleFieldQuery,
    parseArray(setYearQueries?.filter((year) => !setQueries?.length || !setQueries.some((set) => set.includes(year)))),
    parseArray(playerNameQueries),
    sportsCardNumber,
    parseArray(removeSportsFromSetNames ? removeSportsFromSets(setQueries) : setQueries),
    parseArray(removeSportsFromSetNames ? '' : sportQueries),
    setVariationQueries ? parseArray(setVariationQueries.filter((v) => v.toLowerCase() !== 'base')) : undefined,
    termsToInclude?.join(' '),
    parseArray(sportsCardGradeQueries),
    sealedWaxBoxTypeQuery,
  ].filter(Boolean);
  let newQuery = queryParts.join(' ');

  let excludedBaseTerms = [];
  let excludeTerms = termsToExclude ? [...termsToExclude] : [];
  //always exclude
  excludedBaseTerms.push('not', 'non');

  if (excludeBreaks && !newQuery.toLowerCase().includes('break')) {
    excludedBaseTerms.push('break');
  }

  if (excludeLots) {
    excludedBaseTerms.push('Lot', 'x2', 'x3');
  }
  if (excludeSealedWaxTerms) {
    excludedBaseTerms.push('Box', 'Pack', 'Sealed', 'Set', 'Case');
  }
  //group and minimize to the -() format to save space
  let excludeString = '';
  for (let i = 0; i < excludeTerms.length; i++) {
    if (excludeString.length + excludeTerms[i].length > 98) {
      newQuery += ' -(' + excludeString.substr(0, excludeString.length - 1) + ')';
      excludeString = excludeTerms[i] + ',';
    } else {
      excludeString += excludeTerms[i] + ',';
    }
  }
  if (excludeString) {
    newQuery += ' -(' + excludeString.substr(0, excludeString.length - 1) + ')';
  }
  // Always include the base terms in their own exclusion string
  newQuery += ` -(${excludedBaseTerms.join(',')})`;
  return newQuery;
}

// terms for or condition in ebay have to be like (term1,term2)
const parseArray = (queryTerm: string | string[] | undefined | (string | undefined)[]) => {
  if (Array.isArray(queryTerm) && queryTerm.length > 1) {
    return `(${queryTerm.filter((v) => !!v).join(',')})`;
  } else {
    return queryTerm;
  }
};

// ebay doesn't get good results for things like (Prizm Basketball)
const removeSportsFromSets = (sets: string | string[] | undefined) => {
  const removeSports = (set: string | undefined) => {
    if (!set) return;

    // @TODO is there a global for sports? There should be
    return set
      .replace(/baseball/gi, '')
      .replace(/basketball/gi, '')
      .replace(/boxing/gi, '')
      .replace(/cycling/gi, '')
      .replace(/dc/gi, '')
      .replace(/digimon/gi, '')
      .replace(/football/gi, '')
      .replace(/fortnite/gi, '')
      .replace(/golf/gi, '')
      .replace(/hockey/gi, '')
      .replace(/magic: the gathering/gi, '')
      .replace(/marvel/gi, '')
      .replace(/mma/gi, '')
      .replace(/multi-sport/gi, '')
      .replace(/olympics/gi, '')
      .replace(/pokemon/gi, '')
      .replace(/poker/gi, '')
      .replace(/pop culture/gi, '')
      .replace(/racing/gi, '')
      .replace(/soccer/gi, '')
      .replace(/star wars/gi, '')
      .replace(/tennis/gi, '')
      .replace(/wrestling/gi, '')
      .replace(/yu-gi-oh!/gi, '')
      .trim();
  };
  if (Array.isArray(sets)) {
    return sets.map(removeSports);
  } else {
    return removeSports(sets);
  }
};
