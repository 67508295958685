import { CollectibleType } from '@sportscardinvestor/schemas';
import { ToggleButton } from '@sportscardinvestor/sci-ui/components/buttons/toggle-button';
import { SalesHistoryItem } from '@/features/sales-history/SalesHistoryItem';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';
import VerticalTilesList from '@/sci-ui-components/VerticalTilesList/VerticalTilesList';

export interface SalesHistoryItemsListProps {
  items: RawCompletedSaleSearchItem[];
  className?: string;
  isLoading?: boolean;
  onUseValue: OnUseValueFn;
  onUseImage: OnUseImageFn;
  collectibleType: CollectibleType;
  usingImageItemId: string | null;
  usingPriceItemId: string | null;
}

export type OnUseValueFn = (params: { value: number; itemId: string }) => void;
export type OnUseImageFn = (params: { imageUrl: string; itemId: string }) => void;

export function SalesHistoryItemsList({
  items,
  className,
  isLoading,
  onUseImage,
  onUseValue,
  collectibleType,
  usingImageItemId,
  usingPriceItemId,
}: SalesHistoryItemsListProps) {
  return (
    <VerticalTilesList className={className} isLoading={isLoading}>
      {items.map((item) => {
        const itemId = item.id;
        const imageUrl = item.imageUrls[0];
        const value = item.finalPrice;
        const isUsingImage = itemId === usingImageItemId;
        const isUsingValue = itemId === usingPriceItemId;
        return (
          <SalesHistoryItem
            className="p-2"
            item={item}
            key={itemId}
            collectibleType={collectibleType}
            showListingType
            size="md"
            rightContent={
              <div className="shrink-0 grow-0 flex flex-col items-stretch sm:flex-row sm:items-center gap-2">
                <ToggleButton
                  variant={isUsingImage ? 'primary' : 'default'}
                  size="medium"
                  shape="round"
                  value={isUsingImage}
                  onChange={() => {
                    if (imageUrl) {
                      onUseImage({ imageUrl, itemId });
                    }
                  }}
                  isDisabled={!imageUrl}
                  className="md:min-w-32"
                >
                  Use Photo
                </ToggleButton>
                <ToggleButton
                  variant={isUsingValue ? 'primary' : 'default'}
                  size="medium"
                  shape="round"
                  value={isUsingValue}
                  onChange={() => {
                    if (value) {
                      onUseValue({ value, itemId });
                    }
                  }}
                  isDisabled={!value}
                  className="md:min-w-32"
                >
                  Use Price
                </ToggleButton>
              </div>
            }
          />
        );
      })}
    </VerticalTilesList>
  );
}
