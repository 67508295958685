import { FavoritePlayer } from '../../../../sci-ui-components/types/favoritePlayer';
import { ApiFavoritePlayer } from '../../types';

export default function favoritePlayerFromApi(apiItem: ApiFavoritePlayer, categoryId: number): FavoritePlayer {
  return {
    playerId: apiItem.foreign_id,
    playerName: apiItem.title,
    favoriteId: apiItem.id,
    categoryId: categoryId,
    favoriteHistoricalStats: {
      lastSale: apiItem.lastSale ?? null,
      last30ChangePercentage: apiItem.last30 ?? null,
      last7ChangePercentage: apiItem.last7 ?? null,
      last90ChangePercentage: apiItem.last90 ?? null,
    },
  };
}
