import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { getCardSets } from '../../services/sciApi/cardSets/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { CardSet } from 'sci-ui-components/types/cardSet';
import useFilteredData from 'hooks/useFilteredData';

export type UseCardSetsParams = {
  searchText?: string | null;
  cardSetYears?: number[] | null;
  sportIds?: number[] | null;
};

export const keyPrefix = 'card-sets';
type Key = [typeof keyPrefix];

export default function useCardSets(
  { searchText, cardSetYears, sportIds }: UseCardSetsParams = {},
  options: UseQueryOptions<CardSet[], ApiError, CardSet[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery([keyPrefix], ({ signal }) => getCardSets(signal), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
    enabled: !!isLoggedIn && (options?.enabled ?? true),
  });

  // TODO: this should be done in API instead
  const filterByYears = useMemo(() => {
    const cardSetYearsSet = new Set(cardSetYears);
    return ({ year }: CardSet) => !cardSetYearsSet.size || cardSetYearsSet.has(Number(year));
  }, [cardSetYears]);
  const filterBySportIds = useMemo(() => {
    const sportIdsSet = new Set(sportIds);
    return ({ sportId }: CardSet) => !sportIdsSet.size || sportIdsSet.has(sportId);
  }, [sportIds]);
  const filteredData = useFilteredData(queryResult.data, 'displayText', searchText, [filterByYears, filterBySportIds]);

  return {
    ...queryResult,
    data: filteredData,
  };
}
