import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { formatter, parser } from '../utils/numberFieldHelpes';

import classes from './PriceField.module.scss';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';

export type PriceFieldProps = Omit<
  InputNumberProps<number>,
  'onChange' | 'formatter' | 'parser' | 'value' | 'onChange' | 'type'
> & {
  prefixFaIcon?: IconDefinition;
  value: number | null | undefined;
  onChange?: (newValue: number | null, name?: string) => void;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

export default React.forwardRef<HTMLInputElement, PriceFieldProps>(function PriceField(
  {
    className,
    value,
    onChange,
    minimumFractionDigits,
    maximumFractionDigits,
    placeholder = 'Enter amount..',
    id,
    name,
    ...other
  }: PriceFieldProps,
  ref
) {
  const { selectedCurrencySymbol } = useCurrencyFormatter();
  const handleChange = (value: number | null) => {
    onChange?.(value, name);
  };
  return (
    <InputNumber
      {...other}
      ref={ref}
      onChange={handleChange}
      value={typeof value === 'number' ? value : undefined}
      placeholder={placeholder}
      className={clsx(classes.root, className)}
      id={id}
      name={name}
      prefix={selectedCurrencySymbol}
      formatter={
        (value, { userTyping }) => formatter(value, userTyping ? 0 : minimumFractionDigits, maximumFractionDigits) // NOTE: do not add fraction part while user is still typing
      }
      /* @ts-ignore-nex*/ // NOTE: bug in antd: empty values should not be forced to numbers
      parser={parser}
    />
  );
});
