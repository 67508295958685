import {
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import { getOSTextSearchParams } from '../sales/helpers';

type UseSetVariationOptionsQueryInput = MmApiInput['public']['collectibles']['filters']['variationOptions'];
type UseSetVariationOptionsFilters = Exclude<UseSetVariationOptionsQueryInput['filters'], void>;
export type UseSetVariationOptionsOutput = MmApiOutput['private']['collectibles']['filters']['variationOptions'];
export type SetVariationOption = Exclude<UseSetVariationOptionsOutput, void | null>['options'][number];

export const useSetVariationOptionsKeyPrefix = 'private.collectibles.filters.variationOptions';
export type UseSetVariationOptionsQueryKey = [typeof useSetVariationOptionsKeyPrefix, UseSetVariationOptionsQueryInput];

const emptyResults: SetVariationOption[] = [];

export type UseSetVariationOptionsInput = Pick<
  UseSetVariationOptionsFilters,
  'playerIds' | 'playerNames' | 'setIds' | 'setNames' | 'setYears' | 'sportIds' | 'sportNames'
> & {
  searchText?: string | null;
};

export function useSetVariationOptions(
  { searchText, ...rest }: UseSetVariationOptionsInput,
  options?: MmApiQueryOptions<UseSetVariationOptionsOutput, UseSetVariationOptionsQueryKey>
) {
  const input: UseSetVariationOptionsQueryInput = {
    ...rest,
    filters: {
      ...rest,
      collectibleTypes: ['sports-card'],
    },
    searchQueryText: searchText?.trim() ? getOSTextSearchParams(searchText) : undefined,
  };
  const queryKey: UseSetVariationOptionsQueryKey = [useSetVariationOptionsKeyPrefix, input];
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    async () => mmApiClient.private.collectibles.filters.variationOptions.query(input),
    options
  );
  const items = result.data?.options ?? emptyResults;
  return {
    ...result,
    items,
  };
}
