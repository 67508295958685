import { createContext, ReactNode, useContext } from 'react';
import { CollectibleType } from './constants';

export const CollectibleTypeValueContext = createContext<CollectibleType | undefined>(undefined);

export function CollectibleTypeValueContextProvider({
  children,
  collectibleType,
}: {
  children: ReactNode | ReactNode[];
  collectibleType: CollectibleType;
}) {
  return (
    <CollectibleTypeValueContext.Provider value={collectibleType}>{children}</CollectibleTypeValueContext.Provider>
  );
}

export function useCollectibleTypeValue() {
  const value = useContext(CollectibleTypeValueContext);
  if (!value) {
    throw new Error('useCollectibleTypeValue must be used within CollectibleTypeValueContextProvider');
  }
  return value;
}
