import * as Sentry from '@sentry/nextjs';
import publicConfig from '../../publicConfig';
import { checkResponse, ApiError, isApiError, isAbortError } from '../../utils/api';

export default async function fetchFromSciApi<TBody extends any = any>(
  path: string,
  requestInit: RequestInit,
  isPublic = false
) {
  try {
    if (!publicConfig.NEXT_PUBLIC_SCI_API_BASE_URL) {
      throw new ApiError('Failed to fetch from SCI api', 500, {
        message: 'SCI API base url is not configured',
      });
    }
    const response = await fetch(`${publicConfig.NEXT_PUBLIC_SCI_API_BASE_URL}/${isPublic ? '' : 'api'}${path}`, {
      ...requestInit,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(requestInit.headers ?? {}),
      },
    });
    return await checkResponse<TBody>(response);
  } catch (err: any) {
    if (isAbortError(err)) {
      throw err;
    }

    const errorContext = {
      path,
      isPublic,
      method: requestInit.method ?? 'GET',
    };

    if (isApiError(err)) {
      if (err.status > 500) {
        Sentry.captureException(err, { extra: errorContext });
        console.error(err);
      }
      throw err;
    }
    Sentry.captureException(err, { extra: errorContext });
    console.error(err);
    throw new ApiError('Failed to fetch from SCI API', err?.status ?? 500, err);
  }
}
