import React, { ChangeEventHandler } from 'react';
import { Input, InputProps, InputRef } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import ClearIconButton from '../ClearIconButton/ClearIconButton';

import classes from './TextField.module.scss';

const { TextArea } = Input;

export type TextFieldProps = Omit<InputProps, 'onChange' | 'suffix' | 'value' | 'onPressEnter'> & {
  prefixFaIcon?: IconDefinition;
  value: string | null | undefined;
  onChange?: (newValue: string, name?: string) => void;
  isMultiLine?: boolean;
  multilineContainerClassName?: string;
  rows?: number;
  autoSize?: boolean;
  onPressEnter?: () => void;
};

export default React.forwardRef<InputRef, TextFieldProps>(function TextField(
  {
    className,
    value,
    onChange,
    placeholder = 'Type something..',
    id,
    prefixFaIcon,
    prefix,
    name,
    isMultiLine = false,
    rows = 4,
    autoSize = false,
    onPressEnter,
    disabled,
    type = 'text',
    readOnly,
    multilineContainerClassName,
    ...other
  }: TextFieldProps,
  ref
) {
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    onChange?.(e.target.value, e.target.name);
  };

  if (isMultiLine)
    return (
      <div className={clsx(classes.textAreaWrapper, 'relative', multilineContainerClassName)}>
        <TextArea
          ref={ref}
          disabled={disabled}
          onChange={handleChange}
          onPressEnter={onPressEnter}
          value={value || ''}
          placeholder={placeholder}
          className={className}
          id={id}
          name={name}
          autoSize={autoSize}
          rows={rows}
          readOnly={readOnly}
        />
        {!disabled && !readOnly && (
          <ClearIconButton onClick={() => onChange?.('', name)} visible={!!value} className="absolute top-1 right-2" />
        )}
      </div>
    );

  return (
    <Input
      {...other}
      type={type}
      disabled={disabled}
      onChange={handleChange}
      onPressEnter={onPressEnter}
      value={value || ''}
      placeholder={placeholder}
      className={clsx(classes.textField, className)}
      id={id}
      name={name}
      prefix={prefix ?? (prefixFaIcon ? <FontAwesomeIcon icon={prefixFaIcon} /> : undefined)}
      suffix={
        disabled || readOnly ? undefined : <ClearIconButton onClick={() => onChange?.('', name)} visible={!!value} />
      }
      readOnly={readOnly}
      ref={ref}
    />
  );
});
