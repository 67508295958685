import { z } from 'zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import {
  getCollectibleCardNumber,
  getCollectibleGradeName,
  getCollectiblePlayerName,
  getCollectibleSpecificQualifier,
  getCollectibleVariationName,
  isSportsCardCollectible,
} from '@sportscardinvestor/collectible-helpers';
import { ImageUrlField, getImageUrlFormInitialValues, imageUrlFormSchema } from './imageUrl';
import { SetField, getSetInitialValues, setFormSchema } from './set';
import { SportField, getSportInitialValues, sportFormSchema } from './sport';
import { SalesHistoryItemInfo } from './SalesHistoryItemInfo';
import { EditCustomCollectionItemCallbacks } from './callbacks';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { EditForm, EditFormButtons, FormSeparator } from '@/sci-ui-components/forms/EditForm';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { PlayersAutoComplete } from '@/features/players/PlayersAutoComplete';
import { CardGradesAutoComplete } from '@/features/cardGrades/CardGradesAutoComplete';
import TextField from '@/sci-ui-components/forms/TextField/TextField';
import { SetVariationsAutoComplete } from '@/features/setVariations/SetVariationsAutoComplete';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';
import { CardNumberTextField } from '@/features/collectibles/CardNumberTextField';

const formSchema = z
  .object({
    playerId: z.number().nullable().default(null),
    playerName: z
      .string({
        required_error: 'Please enter player name',
      })
      .trim()
      .min(1, 'Please enter player name'),
    variationName: z.string().trim().nullable().default(null),
    gradeName: z
      .string({
        required_error: 'Please enter grade name',
      })
      .trim()
      .min(1, 'Please enter grade name'),
    cardNumber: z.string().trim().nullable().default(null),
    specificQualifier: z.string().trim().nullable().default(null),
  })
  .and(imageUrlFormSchema)
  .and(setFormSchema)
  .and(sportFormSchema);

type FormPayload = z.output<typeof formSchema>;
type FormSchema = z.input<typeof formSchema>;
const collectibleType = 'sports-card' as const;
interface SportsCardType {
  collectibleType: 'sports-card';
}
export type EditSportsCardCustomCollectionItemFormInitialValues = Partial<FormSchema> & SportsCardType;
export type EditSportsCardCustomCollectionItemFormPayload = FormPayload & SportsCardType;

export type OnEditSportsCardCustomCollectionItemFormSubmitFn = (
  payload: EditSportsCardCustomCollectionItemFormPayload
) => void;

export function EditSportsCardCustomCollectionItemForm({
  initialValues,
  onSubmit,
  formId,
  onCancel,
  isLoading,
  cancelText,
  submitText,
  noImage,
  salesHistoryItem,
  onPhotoSelected,
}: {
  initialValues: EditSportsCardCustomCollectionItemFormInitialValues;
  onSubmit: OnEditSportsCardCustomCollectionItemFormSubmitFn;
  formId: string;
  onCancel: () => void;
  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;
  noImage?: boolean;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
} & EditCustomCollectionItemCallbacks) {
  const form = useForm<FormSchema, unknown, FormPayload>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(initialValues));
  const handleSubmit = form.handleSubmit((payload: FormPayload) => onSubmit({ ...payload, collectibleType }));
  return (
    <FormProvider {...form}>
      <FormSeparator noTopMargin />
      <SalesHistoryItemInfo
        salesHistoryItem={salesHistoryItem}
        className="w-[612px] lg:w-[960px] xl:w-[1024px] max-w-full mb-4"
      />
      <EditForm
        id={formId}
        onSubmit={handleSubmit}
        className="flex flex-col items-stretch gap-4 lg:flex-row lg:items-start md:gap-8 w-[612px] lg:w-[960px] xl:w-[1024px] max-w-full"
      >
        {!noImage && (
          <ImageUrlField
            collectibleType={collectibleType}
            className="grow-0 shrink-0"
            onPhotoSelected={onPhotoSelected}
          />
        )}
        <div className="grow shrink flex flex-col gap-4 md:gap-8">
          <div className="flex flex-col items-stretch justify-start sm:flex-row sm:items-start sm:justify-stretch gap-4">
            <Controller
              name="playerName"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox
                  className="basis-3/6"
                  label="Player"
                  fieldId="playerName"
                  error={fieldState.error?.message}
                  variant="neater"
                >
                  <PlayersAutoComplete
                    id="playerName"
                    value={field.value}
                    onSelect={(data) => {
                      form.setValue('playerId', data?.id ?? null);
                      form.setValue('playerName', data?.name ?? '');
                    }}
                    onChange={(value) => {
                      form.setValue('playerId', null);
                      form.setValue('playerName', value);
                    }}
                  />
                </FieldLabelBox>
              )}
            />
            <SetField className="basis-3/6" />
          </div>
          <div className="flex flex-col items-stretch justify-start sm:flex-row sm:items-start sm:justify-stretch gap-4">
            <SportField className="basis-2/6" />
            <Controller
              name="variationName"
              control={form.control}
              render={({ field, fieldState }) => {
                const selectedSetId = form.watch('setId');
                return (
                  <FieldLabelBox
                    className="basis-4/6"
                    labelClassName="line-clamp-1"
                    fieldId="variationName"
                    error={fieldState.error?.message}
                    variant="neater"
                    optional
                    label={
                      <Tooltip
                        title="Variation is the specific card from the manufacturer's checklist. Examples: Base, Refractor, Silver, Noyz Boyz, Colossal Swatches, etc. We recommend being specific to what the manufacturer calls the card to ensure our system will match it in the future if we add it to our featured card database."
                        trigger={'hover'}
                      >
                        <span>
                          Variation&nbsp;
                          <FontAwesomeIcon icon={faCircleInfo} />
                        </span>
                      </Tooltip>
                    }
                  >
                    <SetVariationsAutoComplete
                      id="variationName"
                      value={field.value}
                      onSelect={(data) => {
                        form.setValue('variationName', data?.variation_name ?? null);
                      }}
                      onChange={(value) => {
                        form.setValue('variationName', value);
                      }}
                      setIds={selectedSetId ? [selectedSetId] : null}
                    />
                  </FieldLabelBox>
                );
              }}
            />
          </div>
          <div className="flex flex-col items-stretch justify-start sm:flex-row sm:items-start sm:justify-stretch gap-4">
            <Controller
              name="gradeName"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox
                  className="basis-2/6"
                  label="Grade"
                  fieldId="gradeName"
                  error={fieldState.error?.message}
                  variant="neater"
                >
                  <CardGradesAutoComplete
                    id="gradeName"
                    value={field.value}
                    onSelect={(data) => {
                      form.setValue('gradeName', data?.name ?? '');
                    }}
                    onChange={(value) => {
                      form.setValue('gradeName', value);
                    }}
                  />
                </FieldLabelBox>
              )}
            />
            <div className="basis-4/6 flex flex-col items-stretch justify-start sm:flex-row sm:items-start sm:justify-stretch gap-4">
              <Controller
                name="cardNumber"
                control={form.control}
                render={({ field, fieldState }) => (
                  <FieldLabelBox
                    className="basis-2/6"
                    label="Card #"
                    fieldId="cardNumber"
                    labelClassName="line-clamp-1"
                    error={fieldState.error?.message}
                    variant="neater"
                    optional
                  >
                    <CardNumberTextField {...field} id="cardNumber" placeholder="" />
                  </FieldLabelBox>
                )}
              />
              <Controller
                name="specificQualifier"
                control={form.control}
                render={({ field, fieldState }) => (
                  <FieldLabelBox
                    className="basis-4/6"
                    labelClassName="line-clamp-1"
                    label={
                      <Tooltip
                        title="This field is optional and can be used to provide additional information about the card such as an image variation description (ex: “Bat Down”) or to describe a Base Subset (like “Stat Leaders”)"
                        trigger={'hover'}
                      >
                        <span>
                          Image Description &nbsp;
                          <FontAwesomeIcon icon={faCircleInfo} />
                        </span>
                      </Tooltip>
                    }
                    fieldId="specificQualifier"
                    error={fieldState.error?.message}
                    variant="neater"
                    optional
                  >
                    <TextField {...field} id="specificQualifier" placeholder="" />
                  </FieldLabelBox>
                )}
              />
            </div>
          </div>
        </div>
      </EditForm>
      <EditFormButtons
        onCancel={onCancel}
        isLoading={isLoading}
        formId={formId}
        submitText={submitText}
        cancelText={cancelText}
      />
    </FormProvider>
  );
}

export function getEditSportsCardCustomCollectionItemFormInitialValues({
  collectible,
}: {
  collectible: CustomCollectible | null;
}): EditSportsCardCustomCollectionItemFormInitialValues {
  if (!collectible) {
    return {
      gradeName: '',
      setName: '',
      collectibleType,
    };
  }
  return {
    ...getImageUrlFormInitialValues({ collectible }),
    ...getSetInitialValues({ collectible }),
    ...getSportInitialValues({ collectible }),
    gradeName: getCollectibleGradeName(collectible) ?? '',
    cardNumber: getCollectibleCardNumber(collectible),
    playerId: isSportsCardCollectible(collectible) && collectible.player?.id ? Number(collectible.player?.id) : null,
    playerName: getCollectiblePlayerName(collectible) ?? '',
    specificQualifier: getCollectibleSpecificQualifier(collectible),
    variationName: getCollectibleVariationName(collectible),
    collectibleType,
  };
}
