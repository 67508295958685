import qs from 'qs';
import { ApiSavedSearchType } from 'services/sciApi/types.js';
import { getBooleanParam, getNumericParam, getStringParam } from 'utils/queryString';

export const constructCustomCardQueryString = (terms: QueryTerms, type?: ApiSavedSearchType) => {
  const {
    queryYear,
    queryPlayerName,
    querySet,
    querySport,
    queryVariation,
    querySetVariation,
    queryCardNumber,
    queryBoxTypeId,
    queryBoxTypeName,
    queryOtherTerms,
    queryExcludeTerms,
    queryGrade,
    queryExcludeLots,
    queryExcludeSealed,
    queryOtherTermsNewItem,
    queryExcludeTermsNewItem,
    // Popular Card Charts
    cardId,
    players,
    sets,
    variations,
    setVariations,
    years,
    grades,
    startDate,
    endDate,
    rcOnly,
    // Popular Wax Charts
    sealedWaxId,
    // Sets
    sports,
    setNameYears,
    boxTypes,
    // Intl. Reports - Grade Ratios
    grade1,
    grade2,
    // Intl. Reports - Player Ratios
    player1,
    player2,
    // Intl. Reports - Variation Ratios
    variation1,
    variation2,
  } = terms;

  // This prevents against saving a search with just the exclude lots or exclude sealed checkboxes checked
  const hasMinimumFields = hasMinimumValidFields(terms, type);

  const search = [];
  if (cardId) search.push(`cid=${cardId}`);
  if (sealedWaxId) search.push(`swid=${sealedWaxId}`);
  if (queryYear) search.push(`year=${encodeURIComponent(queryYear)}`);
  if (queryPlayerName) search.push(`player=${encodeURIComponent(queryPlayerName)}`);
  if (querySet) search.push(`set=${encodeURIComponent(querySet)}`);
  if (querySport) search.push(`sport=${encodeURIComponent(querySport)}`);
  if (queryVariation) search.push(`variation=${encodeURIComponent(queryVariation)}`);
  if (querySetVariation) search.push(`set_variation=${encodeURIComponent(querySetVariation)}`);
  if (queryCardNumber) search.push(`number=${encodeURIComponent(queryCardNumber)}`);
  if (queryOtherTerms) search.push(`other_terms=${encodeURIComponent(queryOtherTerms.join(','))}`);
  if (queryExcludeTerms) search.push(`exclude_terms=${encodeURIComponent(queryExcludeTerms.join(','))}`);
  if (queryGrade) search.push(`grade=${encodeURIComponent(queryGrade)}`);
  // We don't want to save a query that's just exclude_lots=trencodeURIComponent(ue - that's pretty use)less
  if (queryExcludeLots && hasMinimumFields) search.push(`exclude_lots=${encodeURIComponent(queryExcludeLots)}`);
  if (queryExcludeSealed && hasMinimumFields) search.push(`exclude_sealed=${encodeURIComponent(queryExcludeSealed)}`);
  if (queryOtherTermsNewItem) search.push(`other_terms_new_item=${encodeURIComponent(queryOtherTermsNewItem)}`);
  if (queryExcludeTermsNewItem) search.push(`exclude_terms_new_item=${encodeURIComponent(queryExcludeTermsNewItem)}`);
  // Popular Card Charts terms
  if (players) search.push(`players=${players.join(',')}`);
  if (sets) search.push(`sets=${sets.join(',')}`);
  if (variations) search.push(`variations=${variations.join(',')}`);
  if (setVariations) search.push(`set_variations=${setVariations.join(',')}`);
  if (years) search.push(`years=${years.join(',')}`);
  if (grades) search.push(`grades=${grades.join(',')}`);
  if (startDate) search.push(`start_date=${encodeURIComponent(startDate)}`);
  if (endDate) search.push(`end_date=${encodeURIComponent(endDate)}`);
  if (sports) search.push(`sports=${sports.join(',')}`);
  if (setNameYears) search.push(`set_name_years=${setNameYears.join(',')}`);
  if (boxTypes) search.push(`box_types=${boxTypes.join(',')}`);
  if (queryBoxTypeId || queryBoxTypeName)
    search.push(`sealed_type=${encodeURIComponent(queryBoxTypeId ?? queryBoxTypeName!!)}`);
  if (rcOnly) search.push('rc_only=true');

  // Intl. Reports - Grade Ratios
  if (grade1) search.push(`grade1=${encodeURIComponent(grade1)}`);
  if (grade2) search.push(`grade2=${encodeURIComponent(grade2)}`);

  // Intl. Reports - Variation Ratios
  if (variation1) search.push(`variation1=${encodeURIComponent(variation1)}`);
  if (variation2) search.push(`variation2=${encodeURIComponent(variation2)}`);

  // Intl. Reports - Player Ratios
  if (player1) search.push(`player1=${encodeURIComponent(player1)}`);
  if (player2) search.push(`player2=${encodeURIComponent(player2)}`);

  return search.length ? `?${search.join('&')}` : '';
};

const hasMinimumValidFields = (terms: QueryTerms, type?: ApiSavedSearchType) => {
  switch (type) {
    case 'SALES_LOOKUP':
      return (
        terms.queryYear ||
        terms.querySet ||
        terms.queryVariation ||
        terms.queryCardNumber ||
        terms.queryOtherTerms?.length ||
        terms.queryExcludeTerms?.length
      );
    default:
      return true;
  }
};

type QueryTerms = {
  queryYear?: string;
  queryPlayerName?: string;
  querySet?: string;
  querySport?: string;
  queryVariation?: string;
  querySetVariation?: string;
  queryCardNumber?: number;
  queryBoxTypeId?: string;
  queryBoxTypeName?: string;
  queryOtherTerms?: string[];
  queryExcludeTerms?: string[];
  queryGrade?: string;
  queryExcludeLots?: boolean;
  queryExcludeSealed?: boolean;
  queryOtherTermsNewItem?: string;
  queryExcludeTermsNewItem?: string;
  // Popular Card Charts
  cardId?: string;
  players?: string[];
  sets?: string[];
  variations?: string[];
  setVariations?: string[];
  years?: string[];
  grades?: string[];
  startDate?: string;
  endDate?: string;
  rcOnly?: boolean;
  // Popular Wax Charts
  sealedWaxId?: string;
  // Sets
  sports?: string[];
  setNameYears?: string[];
  boxTypes?: string[];
  // Intl. Reports - Grade Ratios
  grade1?: string;
  grade2?: string;
  // Intl. Reports - Player Ratios
  player1?: string;
  player2?: string;
  // Intl. Reports - Variation Ratios
  variation1?: string;
  variation2?: string;
};

export function parseCustomCardQueryString(query: string): QueryTerms {
  const parsedQuery = qs.parse(query, { parseArrays: true });

  let queryOtherTerms = getStringParam<string | undefined>(parsedQuery, 'other_terms', undefined)?.split(',');
  const queryOtherTermsNewItem = getStringParam<string | undefined>(parsedQuery, 'other_terms_new_item', undefined);
  if (queryOtherTermsNewItem) {
    queryOtherTerms = queryOtherTerms ? queryOtherTerms.concat(queryOtherTermsNewItem) : [queryOtherTermsNewItem];
  }
  let queryExcludeTerms = getStringParam<string | undefined>(parsedQuery, 'exclude_terms', undefined)?.split(',');
  const queryExcludeTermsNewItem = getStringParam<string | undefined>(parsedQuery, 'exclude_terms_new_item', undefined);
  if (queryExcludeTermsNewItem) {
    queryExcludeTerms = queryExcludeTerms
      ? queryExcludeTerms.concat(queryExcludeTermsNewItem)
      : [queryExcludeTermsNewItem];
  }

  return {
    cardId: getStringParam<string | undefined>(parsedQuery, 'cid', undefined),
    sealedWaxId: getStringParam<string | undefined>(parsedQuery, 'swid', undefined),
    queryYear: getStringParam<string | undefined>(parsedQuery, 'year', undefined),
    queryPlayerName: getStringParam<string | undefined>(parsedQuery, 'player', undefined),
    querySet: getStringParam<string | undefined>(parsedQuery, 'set', undefined),
    querySport: getStringParam<string | undefined>(parsedQuery, 'sport', undefined),
    queryVariation: getStringParam<string | undefined>(parsedQuery, 'variation', undefined),
    querySetVariation: getStringParam<string | undefined>(parsedQuery, 'set_variation', undefined),
    queryCardNumber: getNumericParam<number | undefined>(parsedQuery, 'number', undefined),
    queryGrade: getStringParam<string | undefined>(parsedQuery, 'grade', undefined),
    queryExcludeLots: getBooleanParam<boolean | undefined>(parsedQuery, 'exclude_lots', undefined),
    queryExcludeSealed: getBooleanParam<boolean | undefined>(parsedQuery, 'exclude_sealed', undefined),
    queryBoxTypeName: getStringParam<string | undefined>(parsedQuery, 'sealed_type', undefined),
    queryExcludeTerms,
    queryOtherTerms,
  };
}
