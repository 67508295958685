import React, { useCallback } from 'react';
import { InputRef } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/pro-light-svg-icons';
import TextField, { TextFieldProps } from '@/sci-ui-components/forms/TextField/TextField';

export type CardNumberTextFieldProps = Omit<TextFieldProps, 'prefix' | 'autoComplete'>;

export const CardNumberTextField = React.forwardRef<InputRef, CardNumberTextFieldProps>(function CardNumberTextField(
  { value, onChange, ...rest }: CardNumberTextFieldProps,
  ref
) {
  const normalizedValue = removeStartingHash(value);
  const handleChange = useCallback<Exclude<TextFieldProps['onChange'], void>>(
    (value, name) => {
      if (!onChange) {
        return;
      }
      onChange?.(removeStartingHash(value), name);
    },
    [onChange]
  );
  return (
    <TextField
      {...rest}
      value={normalizedValue}
      onChange={handleChange}
      prefix={
        <span className="border-r -mr-1 pr-1">
          <FontAwesomeIcon icon={faHashtag} />
        </span>
      }
      autoComplete="off"
      ref={ref}
    />
  );
});

function removeStartingHash<T extends string | null | undefined>(v: T): T {
  return (v?.trim().startsWith('#') ? v.replace(/^\s*#/, '') : v) as T;
}
