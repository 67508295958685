import qs from 'qs';
import { ApiPlayer } from '../types';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import playerFromApi from '../collectibles/transformers/playerFromApi';

export interface GetPlayersParams {
  offset: number;
  limit?: number;
  ids?: number[];
}

export async function getPlayers({ limit, offset, ids }: GetPlayersParams, signal: AbortSignal | undefined) {
  const apiItems = await authenticatedFetchFromSciApi<ApiPlayer[]>(
    `/players${qs.stringify(
      {
        id: ids,
        limit,
        skip: offset,
        includeNotes: false,
      },
      {
        skipNulls: true,
        addQueryPrefix: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );
  return apiItems.map(playerFromApi);
}

export interface GetPlayerParams {
  playerId: number;
}

export async function getPlayer({ playerId }: GetPlayerParams, signal: AbortSignal | undefined) {
  const apiItem = await authenticatedFetchFromSciApi<ApiPlayer>(`/players/${playerId}`, {
    method: 'GET',
    signal,
  });
  return playerFromApi(apiItem);
}
