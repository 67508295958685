import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import { CollectibleType } from '../constants';
import { NumericDateRange } from '@/sci-ui-components/utils/date';

interface CollectibleFlyoutState {
  collectibleId: number | null;
  collectibleType: CollectibleType;
  isCustom: boolean;
  dateRangeInDays: NumericDateRange;
  setDateRangeInDays: (dateRangeInDays: NumericDateRange) => void;
  onClose: () => void;
  setCollectibleId: ({ collectibleId }: { collectibleId: number }) => void;
}

export const useCollectibleFlyoutStore = create<CollectibleFlyoutState>()((set) => ({
  collectibleId: null,
  collectibleType: 'sports-card',
  isCustom: false,
  dateRangeInDays: 30,
  setDateRangeInDays: (dateRangeInDays) => set({ dateRangeInDays }),
  onClose: () => set({ collectibleId: null }),
  setCollectibleId: ({ collectibleId }) => set({ collectibleId }),
}));

export function useShallowFlyoutStore<T extends CollectibleFlyoutState, K extends keyof CollectibleFlyoutState>(
  ...items: K[]
): Pick<T, K> {
  const obj = useCollectibleFlyoutStore(
    useShallow((state) => items.reduce((acc, item) => ({ ...acc, [item]: state[item] }), {}))
  );
  return obj as Pick<T, K>;
}

export function showCollectibleDetails({
  collectibleId,
  collectibleType,
  isCustom,
}: {
  collectibleId: number;
  collectibleType: CollectibleType;
  isCustom: boolean;
}) {
  useCollectibleFlyoutStore.setState({
    collectibleId,
    collectibleType,
    isCustom,
  });
}
