import { CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import {
  EditSportsCardCustomCollectionItemForm,
  EditSportsCardCustomCollectionItemFormPayload,
  EditSportsCardCustomCollectionItemFormInitialValues,
  getEditSportsCardCustomCollectionItemFormInitialValues,
} from './EditSportsCardCustomCollectionItemForm';
import {
  EditSealedCustomCollectionItemForm,
  EditSealedCustomCollectionItemFormPayload,
  EditSealedCustomCollectionItemFormInitialValues,
  getEditSealedCustomCollectionItemFormInitialValues,
} from './EditSealedCustomCollectionItemForm';
import { EditCustomCollectionItemCallbacks } from './callbacks';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';

export type EditCustomCollectionItemFormPayload =
  | EditSealedCustomCollectionItemFormPayload
  | EditSportsCardCustomCollectionItemFormPayload;
export type EditCustomCollectionItemFormInitialValues =
  | EditSportsCardCustomCollectionItemFormInitialValues
  | EditSealedCustomCollectionItemFormInitialValues;
export type {
  EditSportsCardCustomCollectionItemFormPayload,
  EditSportsCardCustomCollectionItemFormInitialValues,
} from './EditSportsCardCustomCollectionItemForm';
export type {
  EditSealedCustomCollectionItemFormPayload,
  EditSealedCustomCollectionItemFormInitialValues,
} from './EditSealedCustomCollectionItemForm';

export type OnEditCustomCollectionItemFormSubmitFn = (payload: EditCustomCollectionItemFormPayload) => void;

export function EditCustomCollectionItemForm({
  initialValues,
  formId = 'EditCustomCollectionItemForm',
  isLoading,
  onCancel,
  onSubmit,
  ...rest
}: {
  initialValues: EditCustomCollectionItemFormInitialValues;
  onSubmit: OnEditCustomCollectionItemFormSubmitFn;
  formId?: string;
  onCancel: () => void;
  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;
  noImage?: boolean;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
} & EditCustomCollectionItemCallbacks) {
  if (initialValues.collectibleType === 'sports-card') {
    return (
      <EditSportsCardCustomCollectionItemForm
        {...rest}
        formId={formId}
        initialValues={initialValues}
        isLoading={isLoading}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    );
  }

  if (initialValues.collectibleType === 'sealed-wax-card') {
    return (
      <EditSealedCustomCollectionItemForm
        {...rest}
        formId={formId}
        initialValues={initialValues}
        isLoading={isLoading}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    );
  }

  return null;
}

export function getEditCustomCollectionItemFormInitialValues({
  customCollectible,
  collectibleType,
}: {
  customCollectible: CustomCollectible | null;
  collectibleType: CollectibleType;
}): EditCustomCollectionItemFormInitialValues {
  switch (collectibleType) {
    case 'sealed-wax-card':
      return getEditSealedCustomCollectionItemFormInitialValues({
        collectible: customCollectible ?? null,
      });
    default:
      return getEditSportsCardCustomCollectionItemFormInitialValues({
        collectible: customCollectible ?? null,
      });
  }
}
