import { useState } from 'react';
import { SportsAutoComplete, type SportsAutoCompleteProps } from './SportsAutoComplete';
import { useOnValueChange } from '@/hooks/useOnValueChange';

export { otherSportOption } from './SportsAutoComplete';

export type SportNameSelectProps = Omit<SportsAutoCompleteProps, 'onChange' | 'onBlur'>;

export function SportNameSelect({ value, onSelect, placeholder = 'Make a selection', ...rest }: SportNameSelectProps) {
  const [searchText, setSearchText] = useState(value);
  useOnValueChange(value, () => setSearchText(value));
  const handleSelect: SportsAutoCompleteProps['onSelect'] = (option) => {
    onSelect(option);
  };
  const handleBlur: SportsAutoCompleteProps['onBlur'] = () => {
    setSearchText(value);
  };

  return (
    <SportsAutoComplete
      {...rest}
      placeholder={placeholder}
      value={searchText}
      onChange={setSearchText}
      onSelect={handleSelect}
      onBlur={handleBlur}
    />
  );
}
