import { useCallback, useMemo } from 'react';
import { mmApiClient, MmApiInput, MmApiOutput, useAuthenticatedMMAPIQuery, MmApiQueryOptions } from '@/services/mmApiX';

export type UseAlertIdsInput = Exclude<MmApiInput['private']['alerts']['getAllCollectibleIds'], void>;
export type UseAlertIdsOutput = MmApiOutput['private']['alerts']['getAllCollectibleIds'];
export const useAlertIdsKeyPrefix = 'private.alerts.getAllCollectibleIds' as const;
export type UseAlertIdsKey = [typeof useAlertIdsKeyPrefix, UseAlertIdsInput];
export const getUseAlertIdsQueryKey = (input: UseAlertIdsInput): UseAlertIdsKey => [useAlertIdsKeyPrefix, input];

export function useAlertIds(input: UseAlertIdsInput, options?: MmApiQueryOptions<UseAlertIdsOutput, UseAlertIdsKey>) {
  const { data, isLoading, isError, refetch } = useAuthenticatedMMAPIQuery(
    getUseAlertIdsQueryKey(input),
    async () => mmApiClient.private.alerts.getAllCollectibleIds.query(input),
    options
  );
  const collectibleIdsMap = useMemo(
    () =>
      (data ?? []).reduce<Map<number, number[]>>((acc, { collectibleId, id }) => {
        if (acc.has(collectibleId)) {
          acc.get(collectibleId)!.push(id);
        } else {
          acc.set(collectibleId, [id]);
        }
        return acc;
      }, new Map()),
    [data]
  );
  const alertsCount = data?.length ?? 0;
  const checkIfHasAlert = useCallback((id: number) => collectibleIdsMap.has(id), [collectibleIdsMap]);
  const getAlertIdsByEntityId = useCallback((id: number) => collectibleIdsMap.get(id), [collectibleIdsMap]);
  const getCollectibleIdByAlertId = useCallback(
    (id: number) => data?.find((item) => item.id === id)?.collectibleId,
    [data]
  );

  return {
    isLoading,
    isError,
    refetch,
    alertsCount,
    checkIfHasAlert,
    getAlertIdsByEntityId,
    getCollectibleIdByAlertId,
  };
}
