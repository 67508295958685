import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiCardSet } from '../types';
import cardSetFromApi from '../collectibles/transformers/cardSetFromApi';

export async function getCardSets(signal: AbortSignal | undefined) {
  const apiItems = await authenticatedFetchFromSciApi<ApiCardSet[]>('/sets', {
    method: 'GET',
    signal,
  });
  return apiItems?.map(cardSetFromApi);
}
