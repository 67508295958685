export const collectibleTypes = ['sports-card', 'sealed-wax-card'] as const;
export type CollectibleType = (typeof collectibleTypes)[number];
export type ExtendedCollectibleType = CollectibleType | 'graded-sports-card';

export function isSportsCardCollectibleType(collectibleType: CollectibleType): boolean {
  return collectibleType === 'sports-card';
}

export function isSealedWaxCardCollectibleType(collectibleType: CollectibleType): boolean {
  return collectibleType === 'sealed-wax-card';
}

export function isValidCollectibleType(v: string): v is CollectibleType {
  return collectibleTypes.includes(v as CollectibleType);
}
