export const getOSTextSearchParams = (inputText: string) => {
  const text = inputText.replaceAll('!', '');
  if (!text) {
    return '*';
  }
  if (/(?:\s-|^-|\(.+\)|".+")/.test(text)) {
    // NOTE: This means a query language character has been input (word starts with "-" or has text in brackets or quotes)
    return text;
  }
  if (text.slice(-1) === ' ') {
    return text;
  }
  return `${text}*`; // NOTE: last word may not be typed completely
};

export const formatAuctionId = (
  marketplace: string,
  auctionId: string | number | null,
  isExternalSaleId: boolean = false
) => {
  if (auctionId === null) {
    return '';
  }

  const matches = `${auctionId}`.match(/(\d+)$/);

  switch (marketplace) {
    case 'ebay_auctions':
      return matches ? `Item ID: ${matches[0]}` : '';
    case 'pwcc':
      if (isExternalSaleId && auctionId?.toString().startsWith('WA')) {
        return auctionId;
      }
      return !isExternalSaleId && matches ? `Item ID: ${matches[0]}` : '';
    default:
      return '';
  }
};
