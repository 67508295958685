import { memo } from 'react';
import { AutoComplete, AutoCompleteOption, AutoCompleteProps } from 'sci-ui-components/forms/AutoComplete/AutoComplete';
import { CardSet } from 'sci-ui-components/types/cardSet';
import useCardSets from 'features/cardSets/useCardSets';

export type CardSetsAutoCompleteProps = Omit<AutoCompleteProps<CardSet>, 'options' | 'makeOption'> & {
  cardSetYears?: number[];
};
export const CardSetsAutoComplete = memo(function CardSetsAutoComplete({
  value,
  cardSetYears,
  ...other
}: CardSetsAutoCompleteProps) {
  const { data = [] } = useCardSets({ cardSetYears, searchText: value });
  return <AutoComplete<CardSet> {...other} options={data} value={value} makeOption={makeOption} />;
});

function makeOption(data: CardSet): AutoCompleteOption<CardSet> {
  return {
    label: data.displayText,
    value: data.displayText,
    key: data.id.toString(),
    data,
  };
}
