import {
  isValid,
  parseISO,
  format,
  formatISO,
  subDays,
  startOfYear,
  startOfMonth,
  subYears,
  differenceInDays,
  endOfDay,
  addDays,
  startOfDay,
  getUnixTime,
  setHours,
  isBefore,
  formatDistance,
  addSeconds,
  differenceInMilliseconds,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS } from 'date-fns/locale/en-US';

export type DateRangeDaysCount = number | 'all';

export const defaultDateFormat = 'M/d/yyyy';
export const defaultTimeFormat = 'hh:mm aa zzz';
export const shortTimeFormat = 'HH:mm';
export const defaultTimeZone = 'America/Chicago';

export function formatDateRange({
  endDate,
  startDate,
  prefix = '',
  separator = ' - ',
  suffix = '',
  formatStr = defaultDateFormat,
}: {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  formatStr?: string;
  prefix?: string;
  suffix?: string;
  separator?: string;
}): string {
  return `${prefix}${startDate ? formatDate(startDate, formatStr) : ''}${separator}${
    endDate ? formatDate(endDate, formatStr) : ''
  }${suffix}`;
}

export function formatDate(date: string | Date, formatStr?: string) {
  try {
    return formatDateStrict(date, formatStr);
  } catch (e) {
    console.error('Somehow formatDate is called with with an non date string or Date type value');
    return null;
  }
}

export function formatDateStrict(date: string | Date, formatStr = defaultDateFormat) {
  return format(typeof date === 'string' ? parseISO(date) : date, formatStr);
}

export type DateRange = [Date | null, Date | null];
export type MaybeIsoDate = string | null | undefined;
export type IsoDateRange = [MaybeIsoDate, MaybeIsoDate];
export type DateRangeName =
  | 'Since Yesterday'
  | 'Last 7 days'
  | 'Last 14 days'
  | 'Last 30 days'
  | 'Last 60 days'
  | 'Last 90 days'
  | 'Last 180 days'
  | 'Last 365 days'
  | 'This Month'
  | 'This Year'
  | 'All Data';

export type DateRanges = Partial<Record<DateRangeName, DateRange>>;

export function getAllRanges(today: Date): Record<DateRangeName, DateRange> {
  return {
    'Last 7 days': [subDays(today, 7), today],
    'Last 14 days': [subDays(today, 14), today],
    'Last 30 days': [subDays(today, 30), today],
    'Last 60 days': [subDays(today, 60), today],
    'Last 90 days': [subDays(today, 90), today],
    'Last 180 days': [subDays(today, 180), today],
    'Last 365 days': [subDays(today, 365), today],
    'This Month': [startOfMonth(today), today],
    'This Year': [startOfYear(today), today],
    'Since Yesterday': [subDays(today, 1), today],
    'All Data': [getEarliestStartDate(today), today],
  };
}

export function getRanges(today: Date, rangeNames: DateRangeName[]): DateRanges {
  const all = getAllRanges(today);
  return rangeNames.reduce<DateRanges>((acc, name) => {
    acc[name] = all[name];
    return acc;
  }, {});
}

export function getDefaultRanges(today: Date): DateRanges {
  return getRanges(today, [
    'Last 7 days',
    'Last 14 days',
    'Last 30 days',
    'Last 60 days',
    'Last 90 days',
    'Last 180 days',
    'Last 365 days',
    'This Month',
    'This Year',
    'All Data',
  ]);
}

/**
 * GetDateRangeFromPeriodPreset accepts a number specifing how many days are in
 * the selected range and returns the current date and the date of X amount of
 * days in the past.
 * @param period a number representing the number of days in a range
 * @returns an array of two dates
 */
export function getDateRangeFromPeriodPreset(period: number): Date[] {
  const today: Date = new Date();
  const startDate: Date = subDays(today, period);
  return [startDate, today];
}

export function getEarliestStartDate(today: Date): Date {
  return subYears(today, 22);
}

export function formatISOIfExists(maybeISODate: Date | null | undefined) {
  return maybeISODate ? formatISODate(maybeISODate) : null;
}

export function formatISODate(isoDate: Date) {
  return formatISO(isoDate, { representation: 'date' });
}

export function parseISOIfExists(maybeISODate: string | null | undefined) {
  return maybeISODate ? parseISO(maybeISODate) : null;
}

export function formatDateInTimeZone(
  isoDate: string | Date | null | undefined,
  format = defaultDateFormat,
  timezone = defaultTimeZone
) {
  if (!isoDate) {
    return null;
  }
  const parsedDate = typeof isoDate === 'string' ? parseISO(isoDate) : isoDate;
  return isValid(parsedDate) ? formatInTimeZone(parsedDate, timezone, format, { locale: enUS }) : null;
}

export function getNumberOfDaysInDateRange([start, end]: IsoDateRange) {
  if (!start || !end) {
    return Infinity;
  }
  return differenceInDays(parseISO(end), parseISO(start));
}

export function getStartAndEndDateTimesFromDateRange(dateRange: IsoDateRange): [Date | null, Date | null] {
  const startDate = parseISOIfExists(dateRange[0]);
  const endDateDay = parseISOIfExists(dateRange[1]);
  const endDate = endDateDay && endOfDay(endDateDay);
  return [startDate, endDate];
}

export const defaultDaysOptions = [7, 14, 30, 60, 90, 180, 365];

export const NUMERIC_DATE_RANGE_OPTIONS = [7, 14, 30, 60, 90, 180, 365] as const;
export type NumericDateRange = (typeof NUMERIC_DATE_RANGE_OPTIONS)[number];
export function isNumericDateRange(value: number): value is NumericDateRange {
  return NUMERIC_DATE_RANGE_OPTIONS.indexOf(value as NumericDateRange) !== -1;
}

export function getDaysOptionFromDateRange(
  dateRange: IsoDateRange,
  daysOptions: number[] = defaultDaysOptions,
  today: Date = new Date()
) {
  const todayDateStr = formatISODate(today);
  if (todayDateStr !== dateRange[1] || !dateRange[0]) {
    return null;
  }
  const daysInDateRange = getNumberOfDaysInDateRange(dateRange);
  return daysOptions.find((days) => days === daysInDateRange) ?? null;
}

export function getISODatesInRange(dateRange: IsoDateRange): string[] {
  if (!dateRange[0] || !dateRange[1]) {
    return [];
  }
  const startDate = parseISO(dateRange[0]);
  const endDate = parseISO(dateRange[1]);
  if (startDate > endDate) {
    return [];
  }
  const daysInDateRange = getNumberOfDaysInDateRange(dateRange as [string, string]);
  return Array.from({ length: daysInDateRange + 1 }).map((_, index) => formatISODate(addDays(startDate, index)));
}

export function getIsoStringDateRangeInDateRange(
  date: Date,
  dateRange: DateRangeName = 'Last 30 days'
): [string | null, string | null] {
  const [from, to] = getAllRanges(date)[dateRange];
  return [formatISOIfExists(from), formatISOIfExists(to)];
}

export function getIsoDateRangeFromDate(
  date: Date | string,
  [startDaysShift, endDaysShift]: [number, number]
): IsoDateRange {
  const initialDate = typeof date === 'string' ? parseISO(date) : date;
  return [
    formatISOIfExists(addDays(initialDate, startDaysShift)),
    formatISOIfExists(addDays(initialDate, endDaysShift)),
  ];
}

export function parseUtcAsIfItIsLocal(utcDateString: string) {
  return parseISO(utcDateString.replace(/z$/i, ''));
}

export function treatUtcAsIfItIsLocal(utcDateString: string) {
  return formatISO(parseUtcAsIfItIsLocal(utcDateString));
}

export function getUnixDateRange(
  dateRange: IsoDateRange,
  { roundToDay, modifier }: { roundToDay?: boolean; modifier?: (date: Date) => Date } = {}
): [number | undefined, number | undefined] {
  return [
    getUnixDate(dateRange[0], {
      adjust: roundToDay ? 'startOfDay' : undefined,
      modifier,
    }),
    getUnixDate(dateRange[1], {
      adjust: roundToDay ? 'endOfDay' : undefined,
      modifier,
    }),
  ];
}

function getUnixDate(
  isoDate: MaybeIsoDate,
  { adjust, modifier }: { adjust?: 'startOfDay' | 'endOfDay'; modifier?: (date: Date) => Date } = {}
): number | undefined {
  if (!isoDate) {
    return undefined;
  }
  const parsed = parseISO(isoDate);
  let date: Date = parsed;
  switch (adjust) {
    case 'startOfDay': {
      date = startOfDay(parsed);
      break;
    }
    case 'endOfDay': {
      date = endOfDay(parsed);
      break;
    }
  }
  if (modifier) {
    date = modifier(date);
  }
  return getUnixTime(date);
}

export function setCurrentHours(date: Date) {
  const currentDate = new Date();
  return setHours(date, currentDate.getHours());
}

export function isISODateBefore(isoDate: string, isoDateToCompareAgains: string): boolean {
  return isBefore(parseISO(isoDate), parseISO(isoDateToCompareAgains));
}

export function formatDistanceInSeconds(seconds: number) {
  const date = new Date();
  return formatDistance(addSeconds(date, seconds), date);
}

export function compareISODates<TItem>(getISODate: (item: TItem) => string, direction: 'asc' | 'desc') {
  const directionModifier = direction === 'asc' ? 1 : -1;
  return (i1: TItem, i2: TItem) => {
    const date1 = parseISO(getISODate(i1));
    const date2 = parseISO(getISODate(i2));
    return directionModifier * differenceInMilliseconds(date1, date2);
  };
}
