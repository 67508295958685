import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';

export type UseStructuredDataFromTextInput = MmApiInput['private']['recognition']['getStructuredDataFromText'];
export type UseStructuredDataFromTextOutput = MmApiOutput['private']['recognition']['getStructuredDataFromText'];
export const useStructuredDataFromTextKeyPrefix = 'private.recognition.getStructuredDataFromText';
export type UseStructuredDataFromTextQueryKey = [
  typeof useStructuredDataFromTextKeyPrefix,
  UseStructuredDataFromTextInput
];

export function useStructuredDataFromText(
  input: UseStructuredDataFromTextInput,
  options?: MmApiQueryOptions<UseStructuredDataFromTextOutput, UseStructuredDataFromTextQueryKey>
) {
  const queryKey: UseStructuredDataFromTextQueryKey = [useStructuredDataFromTextKeyPrefix, input];
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    async () => mmApiClient.private.recognition.getStructuredDataFromText.query(input),
    options
  );
  return result;
}

/**
 * Exposes a function to fetch Structured Data from text while still using react-query cache
 */
export function useFetchStructuredDataFromText() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const fetchStructuredDataFromText = useCallback(
    async (input: UseStructuredDataFromTextInput): Promise<UseStructuredDataFromTextOutput> => {
      const queryKey: UseStructuredDataFromTextQueryKey = [useStructuredDataFromTextKeyPrefix, input];
      const cached = queryClient.getQueryData<UseStructuredDataFromTextOutput>(queryKey);
      if (cached) {
        return cached;
      }
      setIsLoading(true);
      try {
        const fetched = await mmApiClient.private.recognition.getStructuredDataFromText.query(input);
        queryClient.setQueryData<UseStructuredDataFromTextOutput>(queryKey, fetched);
        return fetched;
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, queryClient]
  );

  return {
    isLoading,
    fetchStructuredDataFromText,
  };
}
