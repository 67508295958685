import { useCallback } from 'react';
import { isCustomCollectible, makeSetDisplayName } from '@sportscardinvestor/collectible-helpers';
import { Collectible, CustomCollectible } from '@sportscardinvestor/schemas';
import { CollectibleType } from '../../collectibles/constants';
import { useGetImageDataUrl } from '../../images/useGetImageDataUrl';
import {
  EditCustomCollectionItemFormInitialValues,
  getEditCustomCollectionItemFormInitialValues,
} from '../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
import { EditCollectionItemPurchaseDetailsInitialValues } from '../EditCollectionItemPurchaseDetailsDialog/EditCollectionItemPurchaseDetailsForm';
import {
  EditCustomCollectibleValueFormInitialValues,
  getEditCustomCollectibleValueFormInitialValues,
} from '../EditCustomCollectibleValueDialog/EditCustomCollectibleValueForm';
import { useFetchStructuredDataFromText } from '@/features/sales-history/useStructuredDataFromText';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { formatISODate } from '@/sci-ui-components/utils/date';
import { useCollectibleCacheHelper } from '@/features/collectibles/useCollectible';

export type MakeFromSalesHistoryItemFn = (params: SalesHistoryItemParams) => Promise<AddToCollectionInitialValues>;
export type MakeFromCollectibleFn = (params: CollectibleParams) => AddToCollectionInitialValues;
export interface SalesHistoryItemParams {
  item: RawCompletedSaleSearchItem;
  collectibleType: CollectibleType;
  searchText: string | null | undefined;
}
export interface CollectibleParams {
  collectible: Collectible | CustomCollectible;
}
export interface AddToCollectionInitialValues {
  collectibleType: CollectibleType;
  initialPurchaseDetails?: EditCollectionItemPurchaseDetailsInitialValues | null;
  initialCustomCollectibleInfo?: EditCustomCollectionItemFormInitialValues | null;
  initialCustomCollectibleValueInfo?: EditCustomCollectibleValueFormInitialValues | null;
  featuredCollectibleId: null | number;
  initialSearchText?: string | null;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
}

export function useMakeAddToCollectionInitialValues() {
  const { mutateAsync: getImageDataUrl, isLoading: isLoadingImage } = useGetImageDataUrl();
  const { fetchStructuredDataFromText, isLoading: isLoadingExtractedData } = useFetchStructuredDataFromText();
  const { convertPriceFromUSD } = useCurrencyFormatter();
  const { setCollectible } = useCollectibleCacheHelper();

  const makeFromCollectible = useCallback<MakeFromCollectibleFn>(
    ({ collectible }) => {
      if (isCustomCollectible(collectible)) {
        return {
          collectibleType: collectible.collectibleType,
          featuredCollectibleId: null,
          initialCustomCollectibleInfo: getEditCustomCollectionItemFormInitialValues({
            collectibleType: collectible.collectibleType,
            customCollectible: collectible,
          }),
          initialCustomCollectibleValueInfo: getEditCustomCollectibleValueFormInitialValues({
            convertPriceFromUSD,
            customCollectible: collectible,
          }),
        } satisfies AddToCollectionInitialValues;
      } else {
        setCollectible(collectible);
        return {
          collectibleType: collectible.collectibleType,
          featuredCollectibleId: collectible.id,
        } satisfies AddToCollectionInitialValues;
      }
    },
    [convertPriceFromUSD, setCollectible]
  );

  const makeFromSalesHistoryItem = useCallback<MakeFromSalesHistoryItemFn>(
    async ({ collectibleType, item, searchText }) => {
      const currentPriceInDisplayCurency = convertPriceFromUSD({ value: item.finalPrice });
      const initialPurchaseDetails: EditCollectionItemPurchaseDetailsInitialValues = {
        purchasePricePerItemInDisplayCurrency: currentPriceInDisplayCurency,
        quantity: 1,
        purchaseDate: formatISODate(new Date()),
        purchasePriceTotalInDisplayCurrency: currentPriceInDisplayCurency,
      };
      const featuredCollectibleId =
        collectibleType === 'sealed-wax-card' ? item.waxCollectibleId : item.nonWaxCollectibleId;
      if (featuredCollectibleId) {
        return {
          collectibleType,
          featuredCollectibleId,
          initialSearchText: searchText || null,
          initialPurchaseDetails,
          initialCustomCollectibleInfo: null,
          initialCustomCollectibleValueInfo: null,
        } satisfies AddToCollectionInitialValues;
      }
      // NOTE: custom collectible
      const externalImageUrl = item.imageUrls?.[0] ?? null;
      const [extractedData, { base64ImageDataURL: imageUrl }] = await Promise.all([
        fetchStructuredDataFromText({
          collectibleTypes: [collectibleType],
          text: item.displayTitle,
        }),
        externalImageUrl ? getImageDataUrl({ imageURL: externalImageUrl }) : { base64ImageDataURL: null },
      ]);
      const setYear = extractedData.setYear?.toString() ?? '';
      const sportName = extractedData.sport?.text ?? '';
      const initialCustomCollectibleInfo: EditCustomCollectionItemFormInitialValues =
        collectibleType === 'sealed-wax-card'
          ? {
              collectibleType,
              imageUrl,
              setYear,
              sportName,
              boxTypeName: extractedData.boxType?.text,
              setId: extractedData.waxSet?.id,
              setName: makeSetDisplayName({
                setName: extractedData.waxSet?.text ?? extractedData.maybe.setName ?? null,
                setYear,
                sportName,
              }),
            }
          : {
              collectibleType,
              imageUrl,
              setYear,
              sportName,
              cardNumber: extractedData.cardNumber,
              gradeName: extractedData.grade?.text,
              playerId: extractedData.player?.id,
              playerName: extractedData.player?.text ?? extractedData.maybe?.playerName ?? undefined,
              setId: extractedData.set?.id,
              setName: makeSetDisplayName({
                setName: extractedData.set?.text ?? extractedData.maybe?.setName ?? null,
                setYear,
                sportName,
              }),
              specificQualifier: null,
              variationName: extractedData.setVariations?.length === 1 ? extractedData.setVariation?.text : null,
            };
      const initialCustomCollectibleValueInfo: EditCustomCollectibleValueFormInitialValues = {
        currentPriceInDisplayCurency,
        customQuery: searchText?.trim() || null,
        imageUrl,
        itemId: item.id,
      };
      return {
        collectibleType,
        initialSearchText: searchText || null,
        featuredCollectibleId: null,
        initialCustomCollectibleInfo,
        initialCustomCollectibleValueInfo,
        initialPurchaseDetails,
        salesHistoryItem: item,
      } satisfies AddToCollectionInitialValues;
    },
    [getImageDataUrl, fetchStructuredDataFromText, convertPriceFromUSD]
  );

  return {
    makeFromSalesHistoryItem,
    makeFromCollectible,
    isLoading: isLoadingExtractedData || isLoadingImage,
  };
}
