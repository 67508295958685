import { useCallback } from 'react';
import useCurrencyFormatter from './useCurrencyFormatter';
import formatStatValueInternal, {
  FormatStatValueParams,
  FormatStatValueFn,
} from 'sci-ui-components/utils/formatStatValue';

export type { FormatStatValueFn, FormatStatValueParams };

export default function useFormatStatValue() {
  const { selectedCurrencyCode, selectedCurrencySymbol, convertPriceFromUSD } = useCurrencyFormatter();

  const formatStatValue = useCallback(
    ({
      doNotConvertToDisplayCurrency,
      ...params
    }: Omit<FormatStatValueParams, 'convertPriceFromUSD' | 'currencyCode'> & {
      doNotConvertToDisplayCurrency?: boolean;
    }) =>
      formatStatValueInternal({
        ...params,
        convertPriceFromUSD: doNotConvertToDisplayCurrency ? undefined : convertPriceFromUSD,
        currencyCode: selectedCurrencyCode,
      }),
    [selectedCurrencyCode, convertPriceFromUSD]
  );

  return {
    formatStatValue,
    selectedCurrencyCode,
    selectedCurrencySymbol,
  };
}
