import { z } from 'zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import { getCollectibleBoxTypeName } from '@sportscardinvestor/collectible-helpers';
import { ImageUrlField, getImageUrlFormInitialValues, imageUrlFormSchema } from './imageUrl';
import { SetField, getSetInitialValues, setFormSchema } from './set';
import { SportField, getSportInitialValues, sportFormSchema } from './sport';
import { SalesHistoryItemInfo } from './SalesHistoryItemInfo';
import { EditCustomCollectionItemCallbacks } from './callbacks';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { EditForm, EditFormButtons, FormSeparator } from '@/sci-ui-components/forms/EditForm';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { SealedWaxBoxTypeAutoComplete } from '@/features/sealedWaxBoxTypes/SealedWaxBoxTypesAutoComplete';
import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';

const formSchema = z
  .object({
    boxTypeName: z
      .string({
        required_error: 'Please enter box type',
      })
      .trim()
      .min(1, 'Please enter box type'),
  })
  .and(imageUrlFormSchema)
  .and(setFormSchema)
  .and(sportFormSchema);

type FormSchema = z.input<typeof formSchema>;
type FormPayload = z.output<typeof formSchema>;
const collectibleType = 'sealed-wax-card' as const;
interface SealedType {
  collectibleType: 'sealed-wax-card';
}
export type EditSealedCustomCollectionItemFormInitialValues = Partial<FormSchema> & SealedType;
export type EditSealedCustomCollectionItemFormPayload = FormPayload & SealedType;
export type OnEditSealedCustomCollectionItemFormSubmitFn = (payload: EditSealedCustomCollectionItemFormPayload) => void;

export function EditSealedCustomCollectionItemForm({
  initialValues,
  onSubmit,
  formId,
  onCancel,
  isLoading,
  submitText,
  cancelText,
  noImage,
  salesHistoryItem,
  onPhotoSelected,
}: {
  initialValues: EditSealedCustomCollectionItemFormInitialValues;
  onSubmit: OnEditSealedCustomCollectionItemFormSubmitFn;
  formId: string;
  onCancel: () => void;
  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;
  noImage?: boolean;
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
} & EditCustomCollectionItemCallbacks) {
  const form = useForm<FormSchema, unknown, FormPayload>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(initialValues));
  const handleSubmit = form.handleSubmit((payload: FormPayload) => onSubmit({ ...payload, collectibleType }));

  return (
    <FormProvider {...form}>
      <FormSeparator noTopMargin />
      <SalesHistoryItemInfo salesHistoryItem={salesHistoryItem} className="w-[612px] md:w-[960px] max-w-full mb-4" />
      <EditForm
        id={formId}
        onSubmit={handleSubmit}
        className="flex flex-col items-stretch gap-4 md:flex-row md:items-start md:gap-8 w-[612px] md:w-[960px] max-w-full"
      >
        {!noImage && (
          <ImageUrlField
            collectibleType={collectibleType}
            className="grow-0 shrink-0"
            onPhotoSelected={onPhotoSelected}
          />
        )}
        <div className="grow shrink flex flex-col gap-4 md:gap-8">
          <SetField />
          <Controller
            name="boxTypeName"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox label="Box Type" fieldId="boxTypeName" error={fieldState.error?.message} variant="neater">
                <SealedWaxBoxTypeAutoComplete
                  id="boxTypeName"
                  value={field.value}
                  onSelect={(data) => {
                    form.setValue('boxTypeName', data?.name ?? '');
                  }}
                  onChange={(value) => {
                    form.setValue('boxTypeName', value);
                  }}
                />
              </FieldLabelBox>
            )}
          />
          <SportField />
        </div>
      </EditForm>
      <EditFormButtons
        onCancel={onCancel}
        isLoading={isLoading}
        formId={formId}
        submitText={submitText}
        cancelText={cancelText}
      />
    </FormProvider>
  );
}

export function getEditSealedCustomCollectionItemFormInitialValues({
  collectible,
}: {
  collectible: CustomCollectible | null;
}): EditSealedCustomCollectionItemFormInitialValues {
  if (!collectible) {
    return {
      boxTypeName: '',
      setName: '',
      collectibleType,
    };
  }
  return {
    ...getImageUrlFormInitialValues({ collectible }),
    ...getSetInitialValues({ collectible }),
    ...getSportInitialValues({ collectible }),
    boxTypeName: getCollectibleBoxTypeName(collectible) ?? '',
    collectibleType,
  };
}
