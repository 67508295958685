import { SealedWaxBoxType } from '../../../../sci-ui-components/types/sealedWaxBoxType';
import { ApiSealedWaxBoxType } from '../../types';

export default function sealedWaxBoxtypeFromApi(apiBoxType: ApiSealedWaxBoxType): SealedWaxBoxType {
  return {
    id: apiBoxType.id,
    name: apiBoxType.name,
    query: apiBoxType.query,
  };
}
