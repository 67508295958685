import { memo } from 'react';
import usePlayers from 'features/players/usePlayers';
import { AutoComplete, AutoCompleteOption, AutoCompleteProps } from 'sci-ui-components/forms/AutoComplete/AutoComplete';
import { Player } from 'sci-ui-components/types/player';

export type PlayersAutoCompleteProps = Omit<AutoCompleteProps<Player>, 'options' | 'makeOption'>;

export const PlayersAutoComplete = memo(function PlayersAutoComplete(props: PlayersAutoCompleteProps) {
  const { data = [] } = usePlayers({});

  return <AutoComplete<Player> {...props} options={data} makeOption={makeOption} />;
});

function makeOption(data: Player): AutoCompleteOption<Player> {
  return {
    label: data.name,
    value: data.name,
    key: data.id.toString(),
    data,
  };
}
