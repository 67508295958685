import {
  useAuthenticatedMMAPIQuery,
  MmApiInput,
  MmApiOutput,
  MmApiQueryOptions,
  mmApiClient,
} from '../../services/mmApiX/index';

export type ListExchangeRatesInput = MmApiInput['private']['exchangeRates']['list'];
export type ListExchangeRatesOutput = Exclude<MmApiOutput['private']['exchangeRates']['list'], void>;

export const useListExchangeRatesKey = 'private.exchangeRates.list';
type QueryKey = [typeof useListExchangeRatesKey];

export default function useListExchangeRates(
  params: ListExchangeRatesInput,
  options: MmApiQueryOptions<ListExchangeRatesOutput, QueryKey> = {}
) {
  const queryKey: QueryKey = [useListExchangeRatesKey];
  const result = useAuthenticatedMMAPIQuery(queryKey, () => mmApiClient.private.exchangeRates.list.query(params), {
    staleTime: 1000 * 60 * 60 * 6, // 6 hours
    ...options,
  });

  return result;
}
