import { AnalyticsEvent } from '@sportscardinvestor/analytics-events';
import { DistributiveOmit } from '../../utils/tsUtils';
import { trackFirebaseEvent } from '../../services/firebase/analytics';
import { useAnalyticsStore } from './store';

export function trackEvent(params: DistributiveOmit<AnalyticsEvent, 'membershipTier' | 'userId' | 'pathname'>) {
  const { user, pathname } = useAnalyticsStore.getState();
  const membershipTier = user?.membershipTier;
  const userId = user?.id;
  trackFirebaseEvent({
    ...params,
    membershipTier,
    userId,
    pathname,
  });
}
