import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';

export const playerApiType = 'PLAYER';

export function getApiCollectibleType(collectibleType: CollectibleType) {
  switch (collectibleType) {
    case 'sports-card': {
      return 'CARD';
    }
    case 'sealed-wax-card': {
      return 'SEALED_WAX';
    }
    default: {
      return null;
    }
  }
}
