import { ChangeEventHandler, DragEvent, useRef, useState } from 'react';
import clsx from 'clsx';
import { faCloudArrowUp, faPencil, faCrop, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { Button, ButtonSize } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { fileToDataUrl } from '../../utils/image';

import classes from './ImageUpload.module.scss';

export type ImageUploadProps = {
  className?: string;
  imageUrl: string | null;
  defaultImageUrl: string | null;
  onChange: (params: SelectedImage | null) => void;
  onReCrop?: (imageUrl: string) => void;
  onError?: (error: Error) => void;
  id?: string;
  changeButtonPosition?: 'center' | 'bottom';
  ImageComponent: React.ReactNode;
  buttonsSize?: ButtonSize;
};

export interface SelectedImage {
  file: File;
  imageDataUrl: string;
}

const allowedFormats = ['image/png', 'image/jpg', 'image/jpeg'] as const;
type allowedFormat = (typeof allowedFormats)[number];
function isAllowedFormat(fileType: string): fileType is allowedFormat {
  return allowedFormats.includes(fileType as allowedFormat);
}

export default function ImageUpload({
  className,
  imageUrl,
  defaultImageUrl,
  onChange,
  onReCrop,
  onError,
  id,
  ImageComponent,
  changeButtonPosition = 'center',
  buttonsSize = 'small',
}: ImageUploadProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFile = async (file: File | null) => {
    if (!file) {
      return onChange(null);
    }
    setIsLoading(false);
    try {
      const imageDataUrl = await fileToDataUrl(file);
      return onChange({
        file,
        imageDataUrl,
      });
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const {
      target: { files },
    } = e;
    handleFile(files?.[0] ?? null);
  };

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragOver(true);
    } else if (e.type === 'dragleave') {
      setIsDragOver(false);
    }
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    const {
      dataTransfer: { files },
    } = e;
    const allowedFiles = Array.from(files ?? []).filter((f) => isAllowedFormat(f.type));
    if (allowedFiles?.length) {
      handleFile(allowedFiles[0] ?? null);
    } else {
      if (files?.length) {
        onError?.(
          new Error(`File type is not supported. Please use one of the following types: ${allowedFormats.join(', ')}`)
        );
      }
    }
  };

  const hasValue = !!imageUrl || !!defaultImageUrl;

  return (
    <label className={clsx(classes.root, 'twp', className)} onDragEnter={handleDrag}>
      <div className={classes.imageWrapper}>
        {ImageComponent}
        <div
          className={clsx('flex gap-2 flex-col items-center', {
            [classes.buttonWrapper]: changeButtonPosition === 'center',
          })}
        >
          <Button
            className={clsx({
              'min-w-28': buttonsSize !== 'xsmall',
            })}
            variant="default"
            size={buttonsSize}
            onClick={() => ref.current?.click()}
            faIconLeft={isLoading ? faSpinner : hasValue ? faPencil : faCloudArrowUp}
            iconLeftClassName={isLoading ? 'animate-spin' : undefined}
            shape="round"
          >
            {hasValue ? 'Change' : 'Select'}
          </Button>
          {!!imageUrl && !!onReCrop && (
            <Button
              className={clsx({
                'min-w-28': buttonsSize !== 'xsmall',
              })}
              variant="default"
              size={buttonsSize}
              onClick={() => onReCrop(imageUrl)}
              faIconLeft={isLoading ? faSpinner : faCrop}
              iconLeftClassName={isLoading ? 'animate-spin' : undefined}
              shape="round"
            >
              Crop
            </Button>
          )}
        </div>
      </div>
      <input
        id={id}
        className={classes.input}
        type="file"
        accept={allowedFormats.join(',')}
        onChange={handleChange}
        ref={ref}
      />
      {isDragOver && (
        <div
          className={classes.dropCatcher}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </label>
  );
}
