import { CollectibleColors } from '../../sci-ui-components/styles/collectibleColors';
import { collectibleTypes } from '../../sci-ui-components/types/collectibleType';

export const collectibleOptions = [...collectibleTypes, 'all'] as const;
export type CollectibleOption = (typeof collectibleOptions)[number];

export const collectibleNames: Record<CollectibleOption, CollectibleNames> = {
  'sports-card': {
    singular: 'Sports Card',
    plural: 'Sports Cards',
    shortSingular: 'Card',
    shortPlural: 'Cards',
  },
  'sealed-wax-card': {
    singular: 'Sealed Product',
    plural: 'Sealed Products',
    shortSingular: 'Product',
    shortPlural: 'Products',
  },
  all: {
    singular: 'Any Collectible',
    plural: 'All Collectibles',
    shortSingular: 'Any Collectible',
    shortPlural: 'All Collectibles',
  },
};

export interface CollectibleNames {
  singular: string;
  plural: string;
  shortSingular: string;
  shortPlural: string;
}

export const collectibleColors: Record<CollectibleOption, string> = {
  'sealed-wax-card': CollectibleColors.SealedWax,
  'sports-card': CollectibleColors.SportsCards,
  all: CollectibleColors.All,
};
