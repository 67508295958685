import { ApiError } from '../../utils/api';
import queryClient from '../queryClient';
import { checkIfAuthError } from '../mmApiX/authentication';
import { authQueryKey } from '../mmApiX/types';
import fetchFromSciApi from './fetchFromSciApi';
import { getAuthTokenFromLocalStorage } from './authentication';

export default async function authenticatedFetchFromSciApi<TBody extends any = any>(
  path: string,
  requestInit: RequestInit
) {
  const fn = () => {
    const token = getAuthTokenFromLocalStorage();
    if (!token) {
      throw new ApiError('Unauthenticated', 401, {
        message: 'No auth token',
      });
    }
    return fetchFromSciApi<TBody>(path, {
      ...requestInit,
      headers: {
        ...(requestInit.headers ?? {}),
        Authorization: `Bearer ${token}`,
      },
    });
  };
  try {
    return await fn();
  } catch (err) {
    if (checkIfAuthError(err)) {
      // NOTE: refetch function will be only called once when it is called by many parallel api calls
      await queryClient.refetchQueries(authQueryKey);
      return await fn();
    }
    throw err;
  }
}
