import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { GetSportsParams, getSports } from '../../services/sciApi/sports/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { Sport } from '../../sci-ui-components/types/sport';
import useFilteredData from 'hooks/useFilteredData';

export type { Sport } from '../../sci-ui-components/types/sport';

export type UseSportsParams = Omit<GetSportsParams, 'offset'> & {
  searchText?: string | null;
};

export const keyPrefix = 'sports';
type UseSportsKey = [typeof keyPrefix, Omit<UseSportsParams, 'searchText'>];

export function useSports(
  { ids, limit, searchText }: UseSportsParams = {},
  options: UseInfiniteQueryOptions<Sport[], ApiError, Sport[], Sport[], UseSportsKey> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useInfiniteQuery(
    [
      keyPrefix,
      {
        limit,
        ids,
      },
    ],
    async ({ pageParam: offset = 0, signal }) =>
      getSports(
        {
          ids,
          limit,
          offset,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      ...options,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.length || !limit) {
          return null;
        }
        const totalItems = allPages.reduce((acc, items) => acc + items.length, 0);
        return totalItems;
      },
    }
  );

  // TODO: this should be done in API instead
  const data = useMemo(
    () => queryResult.data?.pages?.reduce<Sport[]>((acc, page) => acc.concat(page), []),
    [queryResult.data]
  );
  const filteredData = useFilteredData(data, 'name', searchText, []);

  return {
    ...queryResult,
    data: filteredData,
  };
}
