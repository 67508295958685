import qs from 'qs';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiFavoriteCategory } from '../types';
import favoritesCategoryFromApi from './transformers/favoritesCategoryFromApi';
import { getApiCollectibleType, playerApiType } from './utils';

export async function getCollectibleFavoritesCategories(
  { collectibleType }: { collectibleType: CollectibleType },
  signal: AbortSignal | undefined
) {
  const type = getApiCollectibleType(collectibleType);
  const apiCategories = await authenticatedFetchFromSciApi<ApiFavoriteCategory[]>(
    `/favorite-categories${qs.stringify(
      {
        type,
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  return apiCategories.map(favoritesCategoryFromApi);
}

export interface CreateCollectibleFavoritesCategoryParams {
  collectibleType: CollectibleType;
  categoryName: string;
}

export async function createCollectibleFavoritesCategory({
  collectibleType,
  categoryName,
}: CreateCollectibleFavoritesCategoryParams) {
  const type = getApiCollectibleType(collectibleType);
  const apiCategory = await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite-categories', {
    method: 'POST',
    body: JSON.stringify({
      name: categoryName,
      type,
    }),
  });

  return favoritesCategoryFromApi(apiCategory);
}

export async function getFavoritePlayersCategories(signal: AbortSignal | undefined) {
  const apiCategories = await authenticatedFetchFromSciApi<ApiFavoriteCategory[]>(
    `/favorite-categories${qs.stringify(
      {
        type: playerApiType,
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  return apiCategories.map(favoritesCategoryFromApi);
}

export interface CreateFavoritePlayersCategoryParams {
  categoryName: string;
}

export async function createFavoritePlayersCategory({ categoryName }: CreateFavoritePlayersCategoryParams) {
  const apiCategory = await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite-categories', {
    method: 'POST',
    body: JSON.stringify({
      name: categoryName,
      type: playerApiType,
    }),
  });

  return favoritesCategoryFromApi(apiCategory);
}

export interface UpdateFavoriteCategoryParams {
  categoryId: number;
  name: string;
}

export async function updateFavoriteCategory({ name, categoryId }: UpdateFavoriteCategoryParams) {
  const apiCategory = await authenticatedFetchFromSciApi<ApiFavoriteCategory>(`/favorite-categories/${categoryId}`, {
    method: 'PUT',
    body: JSON.stringify({
      name,
    }),
  });
  return favoritesCategoryFromApi(apiCategory);
}

export interface DeleteFavoriteCategoryParams {
  categoryId: number;
}

export async function deleteFavoriteCategory({ categoryId }: DeleteFavoriteCategoryParams) {
  await authenticatedFetchFromSciApi<{ meessage: string }>(`/favorite-categories/${categoryId}`, {
    method: 'DELETE',
  });
}
