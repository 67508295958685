import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Button } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { Spinner } from '@sportscardinvestor/sci-ui/components/spinner';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

export type EditFormProps = DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  isLoading?: boolean;
};
export function EditForm({ className, children, isLoading, ...rest }: EditFormProps) {
  return (
    <form
      className={clsx(
        'twp p-4 pb-6 lg:p-5 lg:pb-7 bg-lightBg-accentPrimary rounded-sm',
        isLoading ? 'relative' : undefined,
        className
      )}
      {...rest}
    >
      {children}
      {!!isLoading && <FormLoadingIndicator className="absolute top-0 left-0 w-full h-full" />}
    </form>
  );
}

export function FormLoadingIndicator({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'flex items-center justify-center bg-lightBg-accentPrimary/50 dark:bg-darkBg-accentPrimary/50 p-4 text-2xl',
        className
      )}
    >
      <Spinner />
    </div>
  );
}

export function FormSeparator({ className, noTopMargin }: { className?: string; noTopMargin?: boolean }) {
  return (
    <hr
      className={clsx(
        className,
        'border-b-lightBg-lightGrey border-b-[1px] border-t-0 mb-5',
        noTopMargin ? 'mt-0' : 'mt-5'
      )}
    />
  );
}

export type EditFormButtonsProps = {
  onCancel?: () => void;
  onSubmit?: () => void;
  cancelText?: string;
  submitText?: string;
  isLoading?: boolean;
  formId?: string;
  disableSubmit?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export function EditFormButtons({
  className,
  submitText = 'Save',
  cancelText = 'Cancel',
  onCancel,
  onSubmit,
  isLoading,
  formId,
  disableSubmit,
  ...rest
}: EditFormButtonsProps) {
  return (
    <section className={clsx('twp w-full', className)}>
      <FormSeparator />
      <div className={clsx('flex items-end justify-stretch sm:justify-end gap-2 lg:gap-3', className)} {...rest}>
        {!!onCancel && (
          <Button
            onClick={onCancel}
            size="medium"
            variant="custom"
            shape="round"
            className="border-lightFg-gray border-[1px] text-lightFg-gray uppercase font-semibold flex-grow md:max-w-40 flex items-center"
            disabled={isLoading}
          >
            {cancelText}
          </Button>
        )}
        <Button
          type={onSubmit ? 'button' : 'submit'}
          onClick={onSubmit}
          size="medium"
          variant="primary"
          shape="round"
          faIconLeft={isLoading ? faSpinner : undefined}
          iconLeftClassName={isLoading ? 'animate-spin' : undefined}
          className="flex-grow md:max-w-40"
          disabled={isLoading || disableSubmit}
          form={formId}
        >
          {submitText}
        </Button>
      </div>
    </section>
  );
}
