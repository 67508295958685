import { RawCompletedSaleSearchItem } from '@/features/sales-history/useRawCompletedSalesSearch';

export function SalesHistoryItemInfo({
  className,
  salesHistoryItem,
}: {
  salesHistoryItem?: RawCompletedSaleSearchItem | null;
  className?: string;
}) {
  if (!salesHistoryItem) {
    return null;
  }
  return (
    <p className={className}>
      <span className="font-semibold">Original Listing Title</span>
      <br />
      <span>{salesHistoryItem.displayTitle}</span>
    </p>
  );
}
