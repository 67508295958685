import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { getCardGrades } from '../../services/sciApi/cardGrades/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { CardGrade } from '../../sci-ui-components/types/cardGrade';
import useFilteredData from 'hooks/useFilteredData';

export interface UseCardGradesParams {
  searchText?: string | null;
  gradedOnly?: boolean;
}

export const keyPrefix = 'card-grades';
type UseGradesKey = [typeof keyPrefix];

export default function useCardGrades(
  { searchText, gradedOnly }: UseCardGradesParams = {},
  options: UseQueryOptions<CardGrade[], ApiError, CardGrade[], UseGradesKey> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery([keyPrefix], ({ signal }) => getCardGrades(signal), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
    enabled: !!isLoggedIn && (options?.enabled ?? true),
  });

  // TODO: this should be done in API instead
  const filterByGradedOnly = useMemo<(grade: CardGrade) => boolean>(() => {
    if (!gradedOnly) {
      return () => true;
    }
    return ({ name }) => name.toLowerCase() !== 'raw';
  }, [gradedOnly]);
  const filteredData = useFilteredData(queryResult.data, 'name', searchText, [filterByGradedOnly]);

  return {
    ...queryResult,
    data: filteredData,
  };
}
