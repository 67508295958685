import { UseQueryOptions, useQuery } from 'react-query';
import { getCollectibleFavoritesCategories } from '../../services/sciApi/favorites';
import { FavoriteCategory } from '../../sci-ui-components/types/favoriteCategory';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { ApiError } from '../../utils/api';

export const getKey = (collectibleType: CollectibleType) => ['favorites-category', collectibleType];

export default function useFavoriteCollectibleCategoriesByType(
  {
    collectibleType,
  }: {
    collectibleType: CollectibleType | null;
  },
  options: UseQueryOptions<FavoriteCategory[], ApiError> = {}
) {
  const queryResult = useQuery<FavoriteCategory[], ApiError>(
    getKey(collectibleType!),
    ({ signal }) =>
      getCollectibleFavoritesCategories(
        {
          collectibleType: collectibleType!,
        },
        signal
      ),
    {
      ...options,
      enabled: !!collectibleType && (options?.enabled ?? true),
    }
  );

  return queryResult;
}
